import {
  Box,
  Button,
  HStack,
  Radio,
  RadioGroup,
  SBModal,
  Text,
  useDisclosure,
} from '@swftbox/style-guide';
import { useState } from 'react';
import {
  useCancelPlanRetailerQuery,
  usePausePlanRetailerQuery,
  useResumePlanRetailerQuery,
} from 'src/components/Particles';

type Props = {
  retailerId: string;
  subscriptionId: string;
  type: 'CANCEL' | 'PAUSE' | 'RESUME';
  alreadyCanceled?: boolean;
  isStripeSubscription: boolean;
};

export const ConfirmActionSubscription = ({
  retailerId,
  subscriptionId,
  type,
  alreadyCanceled = false,
  isStripeSubscription = false,
}: Props) => {
  const [cancelImmediately, setCancelImmediately] = useState(alreadyCanceled);

  const { handleCancelPlan } = useCancelPlanRetailerQuery({
    retailerId,
    subscriptionId,
    cancelImmediately,
  });

  const { handlePausePlan } = usePausePlanRetailerQuery({
    retailerId,
    subscriptionId,
  });
  const { handleResumePlan } = useResumePlanRetailerQuery({
    retailerId,
    subscriptionId,
  });

  const { isOpen, onClose, onOpen } = useDisclosure();
  const handleConfirm = async () => {
    try {
      if (type === 'CANCEL') await handleCancelPlan();
      if (type === 'PAUSE') await handlePausePlan();
      if (type === 'RESUME') await handleResumePlan();
    } finally {
      onClose();
    }
  };
  return (
    <>
      <Button
        variant="solid"
        w="100%"
        onClick={() => {
          onOpen();
        }}
        bg={type === 'CANCEL' ? 'red' : 'black'}
        textTransform="capitalize"
      >
        {type.toLowerCase()} Plan{alreadyCanceled && cancelImmediately && ' Immediately'}
      </Button>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={<Text textTransform="capitalize">{type.toLowerCase()} Plan</Text>}
        size="xl"
        body={
          <Box>
            <Text fontSize="text-md" color="gray.700" textTransform="capitalize">
              Are You Sure About {type.toLowerCase()} Subscription ?
            </Text>
            {type === 'CANCEL' && !alreadyCanceled && (
              <RadioGroup
                value={cancelImmediately ? 'cancelImmediately' : 'notCancelImmediately'}
                onChange={(value) => setCancelImmediately(value === 'cancelImmediately')}
              >
                <HStack w="100%" alignItems="stretch" paddingTop={3}>
                  <Radio value="cancelImmediately" w={'50%'}>
                    <Text fontSize={14}>Cancel Immediately</Text>
                  </Radio>
                  <Radio value="notCancelImmediately" w={'50%'}>
                    <Text fontSize={14}>Cancel at end of period</Text>
                  </Radio>
                </HStack>
              </RadioGroup>
            )}
            {type === 'CANCEL' && isStripeSubscription && (
              <Text paddingTop={3} fontSize={10} color="#98A2B2">
                If you cancel immediately, Stripe creates a DRAFT refund for the unused portion of
                the billing period. You can work with the Accounting team to process this partial
                refund.
              </Text>
            )}
          </Box>
        }
        handleConfirm={handleConfirm}
      />
    </>
  );
};
