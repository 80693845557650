import { type AddressInput, type Address } from '../SharedTypes';
import { InvoiceGenerationType } from '../invoices';
import { type Feature } from '../setting';

export enum CompanyStatus {
  pending = 'pending',
  offboarded = 'offboarded',
  active = 'active',
  disabled = 'disabled',
}

export enum CustomerLocationMatchingStrategy {
  NONE = 'NONE',
  DICE_ALGORITHM = 'DICE_ALGORITHM',
}

export enum ShippingLabelSize {
  A6 = 'A6',
  SQUARE = 'SQUARE',
  MINI = 'MINI',
  ALTERNATE = 'ALTERNATE',
}

interface PendingPickupsLocation {
  locationId: string;
  count: number;
}

export interface SimpleRetailer {
  id: string;
  name: string;
  phone: string;
  email: string;
  status: CompanyStatus;
  isDraftEnabled: boolean;
  isPaymentLinksEnabled?: boolean;
  isLeaveAtTheDoorEnabled?: boolean;
  isCallMeEnabled?: boolean;
  isMultiLocationAllowed: boolean;
  bagCollection: boolean;
  isManualPickupEnabled: boolean;
  logo?: string;
  disableCardOnPayment: boolean;
  customerLocationMatchingStrategy: CustomerLocationMatchingStrategy;
  forceExpressReturn?: boolean;
  locations: Address[];
  totalPendingPickups?: PendingPickupsLocation[];
}

export interface RetailerFeature {
  id: string;
  retailerId: string;
  featureKey: string;
  billing: number;
  isEnabled: boolean;
  isDefault: boolean;
  feature: Omit<Feature, 'createdAt' | 'updatedAt' | 'deletedAt'>;
}

export interface Retailer extends SimpleRetailer {
  smsTriggers: string[];
  orderBalance: number;
  inventoryIframe?: string;
  fulfillment?: {
    id: string;
    name: string;
  };
  ownerUser: {
    name: string;
    phone: string;
  };
  billingInfo: BillingInfo;
  legalInfo: LegalInfo;
  revealInventoryPage?: boolean;
  revealOverviewPage?: boolean;
  shippingLabelSize: ShippingLabelSize;
  showBrand: boolean;
  categoryIds: string[];
  emailManifest?: string;
  incomingPickupEmail?: string;
  emailDailyRecap?: string;
  flaggedOrderEmail?: string;
  retailerConfiguration?: {
    isDeliveryBoostEnabled: boolean;
    returnBilling: number;
    rtoBilling: number;
    storageCount: number;
    storageRate: number;
    cardTransactionRate: number;
    cashTransactionRate: number;
    defaultInvoiceType: InvoiceGenerationType;
    avgBasketValue: number;
  };
  features: RetailerFeature[];
  procedures: Procedure[];
  subscription?: RetailerSubscription;
  sendRtoEligibleEmail?: boolean;
}

export interface BillingInfo {
  email: string;
  address: string;
  contactName: string;
  phone: string;
}

export interface LegalInfo {
  registrationCertificate?: string;
  trnCertificate?: string;
  legalAddress?: string;
  retailerId: string;
  id: string;
}
export interface UpdateRetailerInput {
  id: string;
  revealOverviewPage?: boolean;
  isDraftEnabled?: boolean;
  inventoryIframe?: string | null;
  isPaymentLinksEnabled?: boolean;
  isLeaveAtTheDoorEnabled?: boolean;
  isCallMeEnabled?: boolean;
  isMultiLocationAllowed?: boolean;
  bagCollection?: boolean;
  isManualPickupEnabled?: boolean;
  fulfillmentId?: string;
  categoryIds?: string[];
  billingInfo?: {
    email: string;
    address: string;
    phone: string;
    contactName: string;
  };
  email?: string;
  emailManifest?: string;
  incomingPickupEmail?: string;
  emailDailyRecap?: string;
  flaggedOrderEmail?: string;
  legalInfo?: LegalInfo;
  forceExpressReturn?: boolean;
  isDeliveryBoostEnabled?: boolean;
  retailerConfiguration?: {
    isDeliveryBoostEnabled?: boolean;
    returnBilling?: number;
    rtoBilling?: number;
    storageCount?: number;
    storageRate?: number;
    cardTransactionRate?: number;
    cashTransactionRate?: number;
    defaultInvoiceType?: InvoiceGenerationType;
    avgBasketValue?: number;
  };
  showBrand?: boolean;
  shippingLabelSize?: string;
  sendRtoEligibleEmail?: boolean;
}

export interface LegalInfoInput {
  legalAddress: string;
  trnNumber?: number;
  trnCertificate?: string;
  registrationCertificate: string;
}

export interface CreateRetailerInput {
  name: string;
  phone: string;
  email: string;
  ownerName: string;
  ownerEmail: string;
  ownerPhone: string;
  location: AddressInput;
  legalInfo: LegalInfoInput;
  isDraftEnabled: boolean;
  isMultiLocationAllowed: boolean;
  bagCollection: boolean;
  timezone: string;
  categoryIds?: string[];
  currencyId?: string;
  fulfillmentId?: string;
  logo?: string;
}

export interface RetailerConfigsInput {
  retailerId: string;
  returnBilling: number;
  rtoBilling: number;
  storageCount: number;
  storageRate: number;
  cardTransactionRate: number;
  cashTransactionRate: number;
  defaultInvoiceType: InvoiceGenerationType;
}

export interface RetailerConfigsDTO {
  id: string;
  retailerId: string;
  returnBilling: number;
  rtoBilling: number;
  storageCount: number;
  storageRate: number;
  cardTransactionRate: number;
  cashTransactionRate: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface UpsertRetailerFeatureInput {
  retailerId: string;
  features: Array<{
    featureKey: string;
    billing?: number;
    isEnabled?: boolean;
    isDefault?: boolean;
  }>;
}

export interface RetailerFeatureMutationResponse {
  message: string;
}

export enum ProcedureType {
  FULFILLMENT = 'FULFILLMENT',
  DISPATCH = 'DISPATCH',
  DRIVER = 'DRIVER',
}

export interface Procedure {
  id: string;
  retailerId: string;
  type: ProcedureType;
  description: string;
  images: string[];
  videos: string[];
}

export interface CreateProcedureInput {
  retailerId: string;
  type: ProcedureType;
  description: string;
  images?: string[];
  videos?: string[];
}

export interface UpdateProcedureInput extends CreateProcedureInput {
  id: string;
}

export interface RetailerSubscription {
  id: string;
  endDate: string;
  startDate: string;
  status: string;
  isPaused?: boolean;
  isCanceled?: boolean;
  stripeSubscriptionId?: string;
  plan: {
    name: string;
    price: string;
    currency: string;
    id: string;
  };
}
