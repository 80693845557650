import { addDays } from 'date-fns';
import { createContext, type Dispatch, useContext } from 'react';
import { type FilterAction, type OrganizedFilterAction } from '../../providers';
import {
  type FulFillmentOrdersOrganizingFilters,
  type FulfillmentOrdersFilters,
} from '../../providers/FulfillmentOrders/Filters';

interface FulfillmentOrdersFiltersContextProps {
  filters: FulfillmentOrdersFilters;
  organizedFilters: FulFillmentOrdersOrganizingFilters;
}
const initialOrderFiltersRoot = {
  filters: {
    page: 1,
    sortFilter: {},
    flagType: [],
    statusId: [],
  },
  organizedFilters: {
    dateFilter: {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      isSelected: false,
      key: 'selection',
    },
  },
};

export const FulfillmentOrdersFiltersContext = createContext<FulfillmentOrdersFiltersContextProps>({
  ...initialOrderFiltersRoot,
});

type FulfillmentOrdersFiltersActionsContextProps = Dispatch<FilterAction | OrganizedFilterAction>;

export const FulfillmentOrdersFiltersActionsContext =
  createContext<FulfillmentOrdersFiltersActionsContextProps | null>(null);
export function useFulfillmentOrdersFiltersActions() {
  const dispatch = useContext(FulfillmentOrdersFiltersActionsContext);
  return dispatch as FulfillmentOrdersFiltersActionsContextProps;
}
export function useFulfillmentOrdersFilters() {
  return useContext(FulfillmentOrdersFiltersContext);
}

/* -------------------------------------------------------------------------- */
/*                                   Reducer                                  */
/* -------------------------------------------------------------------------- */
