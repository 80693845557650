import {
  Autocomplete,
  Badge,
  Box,
  Button,
  HStack,
  SBModal,
  Text,
  useDisclosure,
} from '@swftbox/style-guide';
import { useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import InfoIcon from 'src/assets/icons/General/info-circle.svg';
import { useChangePlanRetailerQuery, useSubscriptionsPlans } from 'src/components/Particles';

type Props = {
  retailerId: string;
  subscriptionId: string;
  subscriptionPlanId: string;
};

export const ConfirmChangeSubscriptionAction = ({
  retailerId,
  subscriptionId,
  subscriptionPlanId,
}: Props) => {
  const [planId, setPlanId] = useState(subscriptionPlanId);
  const { plans } = useSubscriptionsPlans();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const plansOptions = useMemo(
    () => plans?.map((plan) => ({ label: plan.name, value: plan.id })) || [],
    [plans]
  );

  const { handleChangePlan } = useChangePlanRetailerQuery({
    retailerId,
    subscriptionId,
    planId,
  });

  const handleConfirm = async () => {
    try {
      await handleChangePlan();
    } finally {
      onClose();
    }
  };

  return (
    <>
      <Button
        variant="solid"
        w="100%"
        onClick={() => {
          onOpen();
        }}
        bg="green"
        textTransform="capitalize"
      >
        Change Plan
      </Button>
      <SBModal
        isOpen={isOpen}
        onClose={onClose}
        header={<Text textTransform="capitalize">Change Plan</Text>}
        size="xl"
        body={
          <Box w="100%" h="350px">
            <Badge variant="warning" rounded="8px" px="12px" py="4px" h="auto" w="100%" mb="10px">
              <HStack alignItems="center" alignContent={'end'} spacing="2">
                <SVG src={InfoIcon} stroke="currentColor" width="100px" />
                <Text whiteSpace="normal" textTransform="lowercase">
                  This action will update the partner's subscription, and will send a subscription
                  confirmation email that includes the updated terms of service document. Please
                  proceed if you have a confirmation that they agree to the updated terms.
                </Text>
              </HStack>
            </Badge>
            <Autocomplete
              placeholder="Select Plan"
              options={plansOptions || []}
              value={plansOptions?.find((statusOption) => statusOption?.value === planId)}
              onChange={(val) => {
                // @ts-expect-error, it's just a falsy warning
                setPlanId(val?.value);
              }}
            />
          </Box>
        }
        handleConfirm={handleConfirm}
      />
    </>
  );
};
