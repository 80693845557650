import * as yup from 'yup';

export const EmailSettingsSchema = yup.object().shape({
  emailManifest: yup
    .string()
    .test('emailManifest', function (value) {
      if (!value) return true;

      const emails = value?.split(',').map((email) => email?.trim());

      return emails.every((email) => {
        const isValid = yup.string().email().isValidSync(email);

        if (!isValid) {
          throw new yup.ValidationError(
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `${email} is not a valid email`,
            email,
            'emailManifest'
          );
        }

        return isValid;
      });
    })
    .transform((originalValue) => {
      if (originalValue?.trim() === '') return null;
      return originalValue;
    })
    .nullable(),

  incomingPickupEmail: yup
    .string()
    .test('incomingPickupEmail', function (value) {
      if (!value) return true;

      const emails = value?.split(',').map((email) => email?.trim());

      return emails.every((email) => {
        const isValid = yup.string().email().isValidSync(email);

        if (!isValid) {
          throw new yup.ValidationError(
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `${email} is not a valid email`,
            email,
            'incomingPickupEmail'
          );
        }

        return isValid;
      });
    })
    .transform((originalValue) => {
      if (originalValue?.trim() === '') return null;
      return originalValue;
    })
    .nullable(),

  emailDailyRecap: yup
    .string()
    .test('emailDailyRecap', function (value) {
      if (!value) return true;

      const emails = value?.split(',').map((email) => email?.trim());

      return emails.every((email) => {
        const isValid = yup.string().email().isValidSync(email);

        if (!isValid) {
          throw new yup.ValidationError(
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `${email} is not a valid email`,
            email,
            'emailDailyRecap'
          );
        }

        return isValid;
      });
    })
    .transform((originalValue) => {
      if (originalValue?.trim() === '') return null;
      return originalValue;
    })
    .nullable(),

  flaggedOrderEmail: yup
    .string()
    .test('flaggedOrderEmail', function (value) {
      if (!value) return true;

      const emails = value?.split(',').map((email) => email?.trim());

      return emails.every((email) => {
        const isValid = yup.string().email().isValidSync(email);

        if (!isValid) {
          throw new yup.ValidationError(
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `${email} is not a valid email`,
            email,
            'flaggedOrderEmail'
          );
        }

        return isValid;
      });
    })
    .transform((originalValue) => {
      if (originalValue?.trim() === '') return null;
      return originalValue;
    })
    .nullable(),
});
