import { useMutation } from '@apollo/client';
import { PUSH_ORDERS_TO_DISPATCH, FILTER_ORDERS } from 'src/components/Particles/resolvers/orders/orders.graphql';
import { QueriesStore } from 'src/components/Particles/resolvers/queriesStore';

interface PushOrderToDispatchResponse {
  message: string;
}

interface PushOrderToDispatchProps {
  payload: {
    ids: string[];
  };
  onCompleted: (message: string) => void;
}

export function usePushOrderToDispatch() {
  const [mutate, { loading, error }] = useMutation<
    { pushToDispatch: PushOrderToDispatchResponse },
    { orderIds: { ids: string[] } }
  >(PUSH_ORDERS_TO_DISPATCH);

  async function pushOrderToDispatch({ payload, onCompleted }: PushOrderToDispatchProps) {
    await mutate({
      variables: {
        orderIds: {
          ids: payload.ids,
        },
      },
      onCompleted: (data) => {
        onCompleted(data.pushToDispatch.message);
      },
      refetchQueries: [
        {
          query: FILTER_ORDERS,
          variables: QueriesStore.get('FILTER_ORDERS'),
        },
      ],
    });
  }

  return {
    pushOrderToDispatch,
    loading,
    error,
  };
}
