import * as yup from 'yup';

export const AddItemSchema = yup.object().shape({
  quantity: yup.number().typeError('quantity must be a number').required('quantity is required'),
  name: yup.string().typeError('name must be a string'),
  price: yup.number().typeError('price must be a number'),
  weight: yup.number().typeError('weight must be a number'),
  weightUnit: yup.string().typeError('weight unit must be a string'),
  sku: yup.string().typeError('sku must be a string'),
  skuNumber: yup.string().typeError('sku must be a number').required('sku number is required'),
  variantId: yup.string().typeError('variantId must be a string'),
  variantType: yup.string().typeError('variantType must be a string'),
  description: yup.string().typeError('description must be a string'),
  hsCode: yup.string().typeError('hsCode must be a string'),
});
