import { camelCaseToCapitalizedSpace } from 'src/components/Pages/Orders/Components';
import { OrdersStatusFilter } from 'src/components/Pages/Orders/order.types';

export const statusList = [
  {
    id: OrdersStatusFilter.STARRED,
    checkBoxItem: undefined,
    children: [
      {
        id: OrdersStatusFilter.STARRED,
        name: camelCaseToCapitalizedSpace(OrdersStatusFilter.STARRED),
        payload: { status: [OrdersStatusFilter.IN_FULFILLMENT], flagType: ['star'] },
      },
    ],
  },
  {
    id: OrdersStatusFilter.FLAGGED,
    checkBoxItem: undefined,
    children: [
      {
        id: OrdersStatusFilter.FLAGGED,
        name: camelCaseToCapitalizedSpace(OrdersStatusFilter.FLAGGED),
        payload: {
          status: [OrdersStatusFilter.IN_FULFILLMENT],
          flagType: ['merchant'],
        },
      },
      {
        id: OrdersStatusFilter.CS_CHECK,
        name: camelCaseToCapitalizedSpace(OrdersStatusFilter.CS_CHECK),
        payload: { status: [OrdersStatusFilter.IN_FULFILLMENT], flagType: ['cs'] },
      },
    ],
  },
];

export enum FilterActionSelection {
  CLEAR = 'clear',
  SELECT_ALL = 'selectAll',
  SINGLE_SELECT = 'singleSelect',
  MULTI_SELECT = 'multiSelect',
}
