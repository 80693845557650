import { Box, Flex, WithLoader } from '@swftbox/style-guide';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { type FC, useEffect, useState } from 'react';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import {
  type DateFilter,
  type PaymentFilterArgs,
  PaymentSortableFields,
  type PaymentSortFilter,
  SortOptions,
  useAuthContext,
  usePaymentsQuery,
  useSearch,
} from 'src/components/Particles';
import { PaymentsFilter, PageHeader, PaymentsTable } from './Components';
import { PaymentForm } from './Components/PaymentForm';
import style from './style.module.scss';

const initialValue = [
  {
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: 'selection',
    isSelected: false,
  },
];

export const PaymentsPage: FC = () => {
  const sortFilter: PaymentSortFilter = {
    order: SortOptions.DESC,
    orderBy: PaymentSortableFields.createdAt,
  };
  const [page, setPage] = useState(1);
  const [dateFilter, setDateFilter] = useState<DateFilter>(initialValue[0]);
  const { user } = useAuthContext();

  const [paymentFilter, setPaymentFilter] = useState<PaymentFilterArgs>({
    page,
    perPage: 50,
    ...sortFilter,
  });
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const { payments, loading } = usePaymentsQuery(paymentFilter);
  const { searchText } = useSearch();

  const isSuperAdmin = !!user?.roles?.includes('SuperAdmin');
  const isAdmin = !!user?.roles?.includes('Admin');

  function onPageChange(selected: number) {
    setPage(selected);
  }

  const openPaymentModal = () => {
    setPaymentModalOpen(true);
  };

  const onFilterChange = (filter: PaymentFilterArgs) => {
    setPaymentFilter(filter);
  };

  useEffect(() => {
    const { startDate, endDate, ...rest } = paymentFilter;
    setPaymentFilter({
      ...rest,
      page,
      orderSwftboxTracking: searchText,
      ...(dateFilter?.isSelected && {
        startDate: startOfDay(dateFilter.startDate as Date),
        endDate: endOfDay(dateFilter.endDate as Date),
      }),
    });
  }, [searchText, dateFilter, page, paymentFilter]);

  return (
    <Box className={style.payment_main}>
      <PageHeader
        title="Payment"
        count={payments?.total || 0}
        buttonText={isSuperAdmin || isAdmin ? 'Create Payment' : undefined}
        onButtonClick={openPaymentModal}
      />
      <PaymentsFilter
        onChange={onFilterChange}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        paymentFilter={paymentFilter}
      />
      <WithLoader isLoading={loading}>
        <PaymentsTable
          payments={payments?.data || []}
          onPageChange={onPageChange}
          page={page}
          total={payments?.total || 0}
        />
        {!loading && !payments?.total && (
          <Flex w="100%" h="50vh" justifyContent="center" alignItems="center">
            <EmptyTable title="No Payments Found" />
          </Flex>
        )}
      </WithLoader>
      {paymentModalOpen && <PaymentForm onModalClose={setPaymentModalOpen.bind(null, false)} />}
    </Box>
  );
};
