import { gql } from '@apollo/client';

export const GET_ITEMS_QUERY = gql`
  query Items($orderId: UUID!) {
    Order(id: $orderId) {
      items {
        sku
        skuNumber
        quantity
        name
        id
        serialNumbers
        barcode
        price
        hsCode
      }
    }
  }
`;

export const ADD_ITEMS_QUERY = gql`
  mutation Mutation($addItemsInput: AddItemsInput!) {
    addItems(addItemsInput: $addItemsInput) {
      message
    }
  }
`;
export const UPDATE_ITEMS_QUERY = gql`
  mutation UpdateItems($updateItemInput: [UpdateItemInput!]!) {
    updateItems(updateItemInput: $updateItemInput) {
      message
    }
  }
`;

export const REMOVE_ITEM_MUTATION = gql`
  mutation RemoveItem($removeItemInput: RemoveItemInput!) {
    removeItem(removeItemInput: $removeItemInput) {
      message
    }
  }
`;
