import {
  Autocomplete,
  Box,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  SBInput,
  Stack,
  Switch,
  Text,
  VStack,
  toast,
} from '@swftbox/style-guide';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  type UpsertRetailerFeatureInput,
  useCategories,
  useFulfillmentsQuery,
  useUpdateRetailerMutation,
  type CustomerLocationMatchingStrategy,
  type ShippingLabelSize,
  useUpsertRetailerFeatureMutation,
  type RetailerFeature,
  type Feature,
  useConfiguration,
} from 'src/components/Particles';
import { SectionRow, SubmitAndCancel } from '../../Shared';
import { CustomerLocationMatchingStrategyOptions } from '../../../Helper';

interface PartnerSettingsProps {
  id: string;
  isDraftEnabled: boolean;
  isMultiLocationAllowed: boolean;
  bagCollection: boolean;
  isManualPickupEnabled: boolean;
  fulfillment?: {
    id: string;
    name: string;
  };
  disableCardOnPayment?: boolean;
  status?: string;
  categoryIds: string[];
  customerLocationMatchingStrategy: CustomerLocationMatchingStrategy;
  shippingLabelSize: ShippingLabelSize;
  showBrand: boolean;
  forceExpressReturn?: boolean;
  isPaymentLinksEnabled?: boolean;
  isLeaveAtTheDoorEnabled?: boolean;
  isCallMeEnabled?: boolean;
  retailerConfiguration?: {
    isDeliveryBoostEnabled?: boolean;
    avgBasketValue?: number;
  };
  revealOverviewPage?: boolean;
  setAllowToMove: React.Dispatch<React.SetStateAction<{ page: string; isAllowed: boolean }>>;
  retailerFeatures: RetailerFeature[];
  accountSettingsFeatures: Feature[];
  sendRtoEligibleEmail?: boolean;
}

const statusOptions = [
  { label: 'Off Boarded', value: 'offboarded' },
  { label: 'Pending', value: 'pending' },
  { label: 'Active', value: 'active' },
  { label: 'Disabled', value: 'disabled' },
];

const noFulfillmentOption = {
  label: 'none (no fulfillment)',
  value: 'none',
};

const ShippingLabelOptions = [
  {
    label: 'Default',
    value: 'A6',
  },
  {
    label: 'Square',
    value: 'SQUARE',
  },
  {
    label: 'Mini',
    value: 'MINI',
  },
  {
    label: 'A6 Alternate',
    value: 'ALTERNATE',
  },
];

const convertToNumber = (value: string) => {
  return value === '' ? 0 : Number(value);
};

export function OrderSettings(props: Readonly<PartnerSettingsProps>) {
  const {
    id,
    categoryIds,
    isDraftEnabled,
    isManualPickupEnabled,
    bagCollection,
    isMultiLocationAllowed,
    fulfillment,
    status,
    disableCardOnPayment,
    customerLocationMatchingStrategy,
    forceExpressReturn,
    isPaymentLinksEnabled,
    isLeaveAtTheDoorEnabled,
    isCallMeEnabled,
    shippingLabelSize,
    showBrand,
    retailerConfiguration,
    revealOverviewPage,
    setAllowToMove,
    accountSettingsFeatures,
    retailerFeatures,
    sendRtoEligibleEmail,
  } = props;

  const { configuration: currency } = useConfiguration('currency');
  const { updateRetailer } = useUpdateRetailerMutation();
  const { upsertRetailerFeature, loading } = useUpsertRetailerFeatureMutation();
  const retailerForm = useForm({
    mode: 'all',
    defaultValues: {
      id,
      isDraftEnabled,
      isPaymentLinksEnabled,
      isLeaveAtTheDoorEnabled,
      isCallMeEnabled,
      isMultiLocationAllowed,
      bagCollection,
      isManualPickupEnabled,
      fulfillmentId: fulfillment?.id,
      status,
      categoryIds,
      disableCardOnPayment,
      customerLocationMatchingStrategy,
      forceExpressReturn,
      shippingLabelSize,
      retailerConfiguration: {
        isDeliveryBoostEnabled: retailerConfiguration?.isDeliveryBoostEnabled,
        avgBasketValue: retailerConfiguration?.avgBasketValue,
      },
      revealOverviewPage,
      showBrand,
      sendRtoEligibleEmail,
    },
  });
  const accountForm = useForm<UpsertRetailerFeatureInput>({
    mode: 'all',
    defaultValues: {
      retailerId: id,
      features: accountSettingsFeatures.map((feature) => ({
        featureKey: feature.key,
        billing:
          retailerFeatures.find((retFeature) => retFeature.featureKey === feature.key)?.billing ||
          feature.billing,
        isEnabled:
          retailerFeatures.find((retFeature) => retFeature.featureKey === feature.key)?.isEnabled ||
          false,
      })),
    },
  });
  useEffect(() => {
    const anyDirty = retailerForm.formState.isDirty || accountForm.formState.isDirty;
    setAllowToMove({ page: '', isAllowed: !anyDirty });
  }, [retailerForm.formState.isDirty, accountForm.formState.isDirty, setAllowToMove]);

  const { getFulfillments, fulfillments } = useFulfillmentsQuery();
  const { categories } = useCategories();
  const categoriesOptions = categories.map((category) => ({
    value: category.id,
    label: `${category.name}`,
  }));

  useEffect(() => {
    getFulfillments().catch(console.log);
  }, [getFulfillments]);

  const fulfillmentOptions = fulfillments
    ?.map((fulfillment) => ({
      label: fulfillment.name,
      value: fulfillment.id,
    }))
    .concat(noFulfillmentOption);

  const handleSave = async () => {
    if (retailerForm.formState.isDirty) {
      await updateRetailer(retailerForm.getValues()).catch(console.error);
    }
    if (accountForm.formState.isDirty) {
      await upsertRetailerFeature({
        payload: accountForm.getValues(),
        onCompleted: () => null,
      }).catch(console.error);
    }
    toast.success('Settings updated successfully');
    retailerForm.reset(retailerForm.getValues());
    accountForm.reset(accountForm.getValues());
  };

  return (
    <Box as="form" onSubmit={retailerForm.handleSubmit(handleSave)} h="100%">
      <VStack alignItems="stretch" justifyContent="space-between" h="100%" gap="4">
        <Stack gap={0} spacing="0">
          <SectionRow title="General Setup" description="Configure the account basics.">
            <Controller
              name="status"
              control={retailerForm.control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  label="Status"
                  options={statusOptions}
                  value={statusOptions.find((statusOption) => statusOption.value === value)}
                  onChange={(val) => {
                    // @ts-expect-error, it's just a falsy warning
                    onChange(val?.value);
                  }}
                />
              )}
            />
            <Controller
              name="fulfillmentId"
              control={retailerForm.control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  label="Fulfillment"
                  options={fulfillmentOptions}
                  value={fulfillmentOptions.find((Fulfillment) => Fulfillment.value === value)}
                  onChange={(val) => {
                    // @ts-expect-error, it's just a falsy warning
                    if (val?.value === 'none') onChange(null);
                    // @ts-expect-error, it's just a falsy warning
                    else onChange(val?.value);
                  }}
                />
              )}
            />
            <Controller
              name="categoryIds"
              control={retailerForm.control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  label="Categories"
                  isMulti
                  options={categoriesOptions}
                  value={categoriesOptions.filter((category) => value?.includes(category.value))}
                  className="auto-complete-multi"
                  onChange={(option) => {
                    if (!option) return;
                    // @ts-expect-error it's just to ignore dummy ts warning  revealOverviewPage: boolean;

                    onChange(option.map((opt) => opt.value));
                  }}
                />
              )}
            />
          </SectionRow>

          <Controller
            name="customerLocationMatchingStrategy"
            control={retailerForm.control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                onChange={onChange}
                value={value}
                paddingY="20px"
                borderBottom="1px solid"
                borderColor="gray.200"
              >
                <SectionRow
                  title="Location Matching Strategy"
                  description="Select the address matching strategy that works best for this partner's customers."
                  children={
                    <>
                      {' '}
                      {CustomerLocationMatchingStrategyOptions.map((option) => (
                        <Box>
                          <Radio
                            key={option.value}
                            value={option.value}
                            size="sm"
                            alignContent={'center'}
                            fontWeight="bold"
                            id={option.label}
                          >
                            <FormLabel
                              htmlFor={option.label}
                              cursor="pointer"
                              fontWeight="medium"
                              mb="0"
                            >
                              {option.label}
                            </FormLabel>
                          </Radio>
                          <Text color="gray.500" fontSize="text-sm" ps="6">
                            {option.description}
                          </Text>
                        </Box>
                      ))}
                    </>
                  }
                />
              </RadioGroup>
            )}
          />
          <SectionRow
            title="Partner Experience Customization"
            description="Customize customer experiences and optimize operational workflows."
            children={
              <>
                <Controller
                  name="isDraftEnabled"
                  control={retailerForm.control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Switch
                      id="isDraftEnabled"
                      onChange={onChange}
                      ref={ref}
                      isChecked={value}
                      fontWeight="medium"
                      color="gray.700"
                      fontSize="text-sm"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                    >
                      Enable Draft
                    </Switch>
                  )}
                />
                <Controller
                  name="isMultiLocationAllowed"
                  control={retailerForm.control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Switch
                      id="isMultiLocationAllowed"
                      onChange={onChange}
                      ref={ref}
                      isChecked={value}
                      fontWeight="medium"
                      color="gray.700"
                      fontSize="text-sm"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                    >
                      Allow Multiple Locations
                    </Switch>
                  )}
                />

                <Controller
                  name="forceExpressReturn"
                  control={retailerForm.control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Switch
                      id="forceExpressReturn"
                      onChange={onChange}
                      ref={ref}
                      isChecked={value}
                      fontWeight="medium"
                      color="gray.700"
                      fontSize="text-sm"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                    >
                      Force Express Return
                    </Switch>
                  )}
                />

                <Controller
                  name="disableCardOnPayment"
                  control={retailerForm.control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Switch
                      id="disableCardOnPayment"
                      onChange={onChange}
                      ref={ref}
                      isChecked={value}
                      fontWeight="medium"
                      color="gray.700"
                      fontSize="text-sm"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                    >
                      Disable Card On Payment
                    </Switch>
                  )}
                />

                <Controller
                  name="isPaymentLinksEnabled"
                  control={retailerForm.control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Switch
                      id="isPaymentLinksEnabled"
                      onChange={onChange}
                      ref={ref}
                      isChecked={value}
                      fontWeight="medium"
                      color="gray.700"
                      fontSize="text-sm"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                    >
                      Enable Payment Links
                    </Switch>
                  )}
                />

                <Controller
                  name="isLeaveAtTheDoorEnabled"
                  control={retailerForm.control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Switch
                      id="isLeaveAtTheDoorEnabled"
                      onChange={onChange}
                      ref={ref}
                      isChecked={value}
                      fontWeight="medium"
                      color="gray.700"
                      fontSize="text-sm"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                    >
                      Enable Leave at the Door
                    </Switch>
                  )}
                />
                <Controller
                  name="isCallMeEnabled"
                  control={retailerForm.control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Switch
                      id="isCallMeEnabled"
                      onChange={onChange}
                      ref={ref}
                      isChecked={value}
                      fontWeight="medium"
                      color="gray.700"
                      fontSize="text-sm"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                    >
                      Enable Call Me
                    </Switch>
                  )}
                />
                <Controller
                  name="retailerConfiguration.isDeliveryBoostEnabled"
                  control={retailerForm.control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Switch
                      id="isDeliveryBoostEnabled"
                      onChange={onChange}
                      ref={ref}
                      isChecked={value}
                      fontWeight="medium"
                      color="gray.700"
                      fontSize="text-sm"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                    >
                      Enable Delivery Boost
                    </Switch>
                  )}
                />

                <Controller
                  name="revealOverviewPage"
                  control={retailerForm.control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Switch
                      id="revealOverviewPage"
                      onChange={onChange}
                      ref={ref}
                      isChecked={value}
                      fontWeight="medium"
                      color="gray.700"
                      fontSize="text-sm"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                    >
                      Show Overview Page
                    </Switch>
                  )}
                />

                <Controller
                  name="sendRtoEligibleEmail"
                  control={retailerForm.control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Switch
                      id="sendRtoEligibleEmail"
                      onChange={onChange}
                      ref={ref}
                      isChecked={value}
                      fontWeight="medium"
                      color="gray.700"
                      fontSize="text-sm"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                    >
                      Send RTO Eligible Email
                    </Switch>
                  )}
                />
              </>
            }
          />
          <SectionRow
            title="Package Preparation Configs"
            description="Set up these configurations to better match how the partner prepares packages for pickups"
            children={
              <>
                <Controller
                  name="shippingLabelSize"
                  control={retailerForm.control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      label="Shipping Label Template"
                      options={ShippingLabelOptions}
                      value={ShippingLabelOptions.find((option) => {
                        return value === option.value;
                      })}
                      className="auto-complete-multi"
                      isClearable={false}
                      onChange={(option) => {
                        if (!option) return;
                        onChange((option as { value: string }).value);
                      }}
                    />
                  )}
                />
                <Controller
                  name="showBrand"
                  control={retailerForm.control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Switch
                      id="showBrand"
                      onChange={onChange}
                      ref={ref}
                      isChecked={value}
                      fontWeight="medium"
                      color="gray.700"
                      fontSize="text-sm"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                    >
                      Prioritize Brand
                    </Switch>
                  )}
                />
                <Controller
                  name="isManualPickupEnabled"
                  control={retailerForm.control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Switch
                      id="isManualPickupEnabled"
                      onChange={onChange}
                      ref={ref}
                      isChecked={value}
                      fontWeight="medium"
                      color="gray.700"
                      fontSize="text-sm"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                    >
                      Enable Manual Pickup
                    </Switch>
                  )}
                />
                {(accountSettingsFeatures || []).map((feature, index) => (
                  <HStack gap="8" w="100%">
                    <Box>
                      <Controller
                        name={`features.${index}.isEnabled`}
                        control={accountForm.control}
                        defaultValue={
                          retailerFeatures.find(
                            (retFeature) => retFeature.featureKey === feature.key
                          )?.isEnabled || false
                        }
                        render={({ field: { onChange, value, ref } }) => (
                          <Box>
                            <Switch
                              id={feature.id}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              ref={ref}
                              isChecked={value}
                              fontWeight="medium"
                              color="gray.700"
                              fontSize="text-sm"
                              display="flex"
                              alignItems="center"
                              cursor="pointer"
                            >
                              {feature.name === 'Scannable Merchant Reference Number'
                                ? 'Enable Scannable Merchant Reference Number'
                                : feature.name}
                            </Switch>

                            <FormLabel
                              htmlFor={feature.id}
                              color="gray.500"
                              fontSize="text-sm"
                              fontWeight="regular"
                              ps="10"
                              cursor="pointer"
                            >
                              {feature.description}
                            </FormLabel>
                          </Box>
                        )}
                      />
                      <Controller
                        name={`features.${index}.featureKey`}
                        control={accountForm.control}
                        defaultValue={feature.key}
                        render={({ field }) => (
                          <SBInput type="hidden" {...field} crossOrigin={undefined} />
                        )}
                      />
                    </Box>
                  </HStack>
                ))}
              </>
            }
          />
          <SectionRow
            title="Bag Collection Settings"
            description="Configure the account to allow for bags to be picked up during a customer drop, suitable for meal-prep type partners."
            children={
              <>
                <Controller
                  name="bagCollection"
                  control={retailerForm.control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Switch
                      id="bagCollection"
                      onChange={onChange}
                      ref={ref}
                      isChecked={value}
                      fontWeight="medium"
                      color="gray.700"
                      fontSize="text-sm"
                      display="flex"
                      alignItems="center"
                      cursor="pointer"
                    >
                      Enable Bag Collection
                    </Switch>
                  )}
                />
              </>
            }
          />
          <SectionRow
            title="GMV Calculation"
            description="This field enables a GMV calculation on the partner Overview page, and overrides any data that may be received via Payload."
            children={
              <>
                <Controller
                  name="retailerConfiguration.avgBasketValue"
                  control={retailerForm.control}
                  render={({ field: { onChange, value } }) => (
                    <SBInput
                      type="number"
                      value={value || ''}
                      onChange={(e) => {
                        const val = e.target.value;
                        if (val === '' || Number(val) >= 0) {
                          onChange(convertToNumber(val));
                        }
                      }}
                      placeholder="ex. 100"
                      crossOrigin={undefined}
                      label={`Order Average Value (${currency?.value ?? ''})`}
                    />
                  )}
                />
              </>
            }
          />
        </Stack>
        <SubmitAndCancel
          isHidden={retailerForm.formState.isDirty || accountForm.formState.isDirty}
          reset={() => {
            retailerForm.reset();
            accountForm.reset();
          }}
        />
      </VStack>
    </Box>
  );
}
