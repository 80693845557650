import { toast, MenuList, Menu, Tooltip, MenuButton } from '@swftbox/style-guide';
import { useState, useEffect } from 'react';
import {
  type Order,
  type OrderFlag,
  OrderFlagStatus,
  OrderFlagType,
  useFlagOrder,
  useUpdateOrderFlag,
} from 'src/components/Particles';
import SVG from 'react-inlinesvg';
import FlagIcon from 'src/assets/icons/orders/flag.svg';
import { ConfirmFlagModal } from './Modal/ConfirmFlag';

interface FlagOrderProps {
  order: Order;
  isFulfillment?: boolean;
}

export const FlagOrder = ({ order, isFulfillment }: FlagOrderProps) => {
  const [flag, setFlag] = useState<OrderFlag | null>(null);

  const FlagsOptions = [
    ...(isFulfillment ? ['Items Out of Stock', 'Out of Packing Material', 'Incorrect SKUs'] : []),
    'Incorrect address',
    'Incorrect phone number',
    'Customer refused',
    'Update payment type',
    'Missing documents/items',
    'Out of zone',
  ];

  useEffect(() => {
    setFlag(
      order.orderFlags
        ? order.orderFlags.reduce((oldest: OrderFlag | null, current: OrderFlag) => {
            if (
              [OrderFlagType.cs, OrderFlagType.merchant].includes(current.flagType) &&
              current.status !== OrderFlagStatus.resolved &&
              (!oldest || new Date(current.createdAt) < new Date(oldest.createdAt))
            ) {
              return current;
            }
            return oldest;
          }, null)
        : null
    );
  }, [order]);

  const { flagOrder: createFlag, loading: createFlagLoading } = useFlagOrder();
  const { updateOrderFlag: updateFlag, loading: updateFlagLoading } = useUpdateOrderFlag();

  const onChooseFlag = (flagReason?: string) => {
    if (createFlagLoading || updateFlagLoading) return;
    if (flag) {
      updateOrderFlag(flagReason);
    } else {
      createOrderFlag(flagReason);
    }
  };

  function createOrderFlag(flagReason?: string) {
    void createFlag({
      payload: {
        orderFlagInput: {
          orderId: order.id,
          flagType: OrderFlagType.merchant,
          flagReason,
        },
      },
      onCompleted: ({ createOrderFlag }) => {
        if (createOrderFlag.message) {
          setFlag(createOrderFlag.data);
          toast.success(createOrderFlag.message);
        }
      },
    });
  }

  function updateOrderFlag(flagReason?: string) {
    const payload = flagReason
      ? {
          flagReason,
          flagType: OrderFlagType.merchant,
          status: OrderFlagStatus.pending,
        }
      : {
          flagType: OrderFlagType.cs,
          status: OrderFlagStatus.resolved,
        };

    void updateFlag({
      payload: {
        updateOrderFlagInput: {
          id: flag?.id ?? '',
          ...payload,
        },
      },
      onCompleted: ({ updateOrderFlag }) => {
        if (updateOrderFlag.message) {
          setFlag(updateOrderFlag.data);
          toast.success(updateOrderFlag.message);
        }
      },
    });
  }

  return (
    <>
      <Menu>
        <Tooltip label={flag?.flagReason} hasArrow>
          <MenuButton
            aria-label="order flag"
            color="primary.900"
            bg="#EFEFEF"
            h="24px"
            borderRadius="4px"
            boxShadow="none"
            minW="2.5rem"
            _hover={{ backgroundColor: '#9090903b' }}
          >
            <SVG
              src={FlagIcon}
              width="15px"
              fill={
                !flag?.flagType || flag.status === OrderFlagStatus.resolved
                  ? 'none'
                  : flag.flagType === OrderFlagType.merchant
                  ? 'red'
                  : '#32C6F1'
              }
              stroke={
                !flag?.flagType || flag.status === OrderFlagStatus.resolved
                  ? 'currentColor'
                  : flag.flagType === OrderFlagType.merchant
                  ? 'red'
                  : '#32C6F1'
              }
              style={{ margin: 'auto' }}
            />
          </MenuButton>
        </Tooltip>
        <MenuList borderRadius="8px" bg="#fff" border="1px solid #EAECF8" padding="12px" zIndex={1}>
          {FlagsOptions.map((option) => (
            <ConfirmFlagModal
              key={option}
              option={option}
              onChooseFlag={onChooseFlag}
              type="flag"
              order={order}
            />
          ))}
          {flag?.flagType && (
            <ConfirmFlagModal
              option="Clear Flag"
              onChooseFlag={onChooseFlag}
              type="clear"
              order={order}
            />
          )}
        </MenuList>
      </Menu>
    </>
  );
};
