import { addDays } from 'date-fns/esm';
import { type ReactNode, useReducer } from 'react';

import {
  FulfillmentOrdersFiltersActionsContext,
  FulfillmentOrdersFiltersContext,
} from '../../Contexts';
import { rootReducer } from './Filters';

export const initialFulfillmentOrderFiltersRoot = {
  filters: {
    page: 1,
    sortFilter: {},
    flagType: [],
    status: [],
    statusId: [],
  },
  organizedFilters: {
    dateFilter: {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      isSelected: false,
      key: 'selection',
    },
  },
};

export function FulfillmentOrdersFiltersProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(rootReducer, initialFulfillmentOrderFiltersRoot);

  return (
    <FulfillmentOrdersFiltersActionsContext.Provider value={dispatch}>
      <FulfillmentOrdersFiltersContext.Provider value={state}>
        {children}{' '}
      </FulfillmentOrdersFiltersContext.Provider>
    </FulfillmentOrdersFiltersActionsContext.Provider>
  );
}
