import { Button, HStack } from '@swftbox/style-guide';
import { useCallback } from 'react';
import {
  useFulfillmentOrdersFilters,
  useFulfillmentOrdersFiltersActions,
} from 'src/components/Particles';
import { statusList } from './constants';
import { ClearFilter } from 'src/components/Pages/Orders/Components/ClearFilters';

export const StatusFilters = () => {
  const dispatch = useFulfillmentOrdersFiltersActions();

  const {
    filters: { statusId },
  } = useFulfillmentOrdersFilters();

  const handleSelectItem = useCallback(
    (ele: any) => {
      dispatch({
        type: 'ORDER_STATUS_MAP_CHANGE',
        payload: { ...ele.payload },
        id: Array.isArray(ele.id) ? ele.id : [ele.id],
      });
    },
    [dispatch]
  );

  return (
    <HStack spacing="2">
      <HStack className="Numbers" spacing="0" flexWrap="wrap" gap="2">
        {statusList?.map((state) => (
          <HStack
            key={state.id}
            border="1px solid #D0D5DD;"
            h="40px"
            lineHeight="40px"
            borderRadius="8px"
            shadow="xs"
            overflow="hidden"
            spacing="0"
          >
            <>
              {state?.children?.map((filterItem) => (
                <Button
                  p="8px 12px"
                  fontSize="x-small"
                  key={filterItem.id}
                  variant="grouped"
                  bg={statusId?.includes(filterItem.id) ? '#63c3ec' : 'transparent'}
                  color={statusId?.includes(filterItem.id) ? '#fff' : 'gray.900'}
                  _focus={{ opacity: 0.85 }}
                  _hover={{ opacity: 0.85 }}
                  onClick={() => {
                    handleSelectItem(filterItem);
                  }}
                >
                  {`${filterItem.name} `}
                </Button>
              ))}
            </>
          </HStack>
        ))}
      </HStack>
      <ClearFilter />
    </HStack>
  );
};
