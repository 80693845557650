import { Box, HStack, IconWText, Text, toast, Tooltip } from '@swftbox/style-guide';
import { useCallback } from 'react';
import VerifyIcon from 'src/assets/icons/General/verify.svg';
import Svg from 'react-inlinesvg';
import MoneyIcon from 'src/assets/icons/orders/dollar.svg';
import JobIcon from 'src/assets/icons/orders/job.svg';
import AttachmentIcon from 'src/assets/icons/Editor/attachment-01.svg';
import { AssignDriver, RevealSOPs } from 'src/components/Organisms';
import { EditOrderRemarks } from 'src/components/Pages/Fullfillment/Components/Modal/EditOrder';
import { JobDetails } from 'src/components/Pages/Jobs/Components/JobDetails';
import { type Order, useAssignOrder, ProcedureType } from 'src/components/Particles';
import { AttachmentsModal } from '../Modal/Attachments';
import AddCommentModal from '../Modal/Comment/AddCommentModa';
import CommentModal from '../Modal/Comment/CommentModal';
import { RescheduleModal } from '../Modal/Reschedule';
import LeaveAtTheDoorIcon from 'src/assets/icons/leaveAtDoor.svg';
import B2bIcon from 'src/assets/icons/b2b.svg';
import OversizedIcon from 'src/assets/icons/heavy.svg';
import ChilledIcon from 'src/assets/icons/chilled.svg';
import InternationalIcon from 'src/assets/icons/international 2.svg';
import RemoteIcon from 'src/assets/icons/remote.svg';
import { FlagOrder } from '../FlagOrder';

interface DispatchPlanProps {
  order: Order;
}

export const DispatchPlan = ({ order }: DispatchPlanProps) => {
  const { assignOrder } = useAssignOrder();

  const onDriverAssignment = useCallback(
    async (driverId: string, orderId?: string) => {
      try {
        if (!orderId) return false;
        const response = await assignOrder({
          payload: {
            orderIds: [orderId],
            driverId,
          },
        });
        toast.success(response.data?.createQuickRoute.message);
        return true;
      } catch {
        return false;
      }
    },
    [assignOrder]
  );

  const oversizedComments = order.comments?.filter((comment) => comment.type === 'OVERSIZED') || [];
  const oversizedComment =
    oversizedComments.length > 0 ? oversizedComments[0].comment : 'Oversized';

  return (
    <>
      <HStack>
        <FlagOrder order={order} />
        <RescheduleModal order={order} />
      </HStack>
      <HStack>
        <Box bg="#EFEFEF" borderRadius="4" w="max-content">
          <HStack spacing="0">
            <CommentModal order={order} />
            <Text py="1">|</Text>
            <AddCommentModal order={order} />
          </HStack>
        </Box>
        {!order.routeId && order.internationalDetails?.isAssigned ? (
          <Box>
            <Box
              bg="#EFEFEF"
              opacity="0.5"
              minW="110px"
              minH="24px"
              p="1"
              borderRadius="4"
              my="2"
              mr="auto"
            >
              <HStack w="100%" justifyContent="space-between">
                <Svg src={JobIcon} />
                <Text>{order.internationalDetails?.carrierName ?? '-'}</Text>
                <div />
              </HStack>
            </Box>
          </Box>
        ) : order.routeId ? (
          <JobDetails
            id={order.routeId}
            OpenButton={
              <Box
                bg="#EFEFEF"
                minW="110px"
                minH="24px"
                p="1"
                borderRadius="4"
                my="2"
                mr="auto"
                color="primary.900"
              >
                <Tooltip label={order.driverPhone}>
                  <HStack w="100%" justifyContent="space-between">
                    <Svg src={JobIcon} />
                    <Text>{order.driverName || '-'}</Text>
                    <div />
                  </HStack>
                </Tooltip>
              </Box>
            }
          />
        ) : (
          <AssignDriver
            entityName="order"
            onAssign={onDriverAssignment}
            id={order.id}
            orders={[order]}
          >
            <Box>
              <Tooltip label="Assign Driver">
                <Box
                  bg="#EFEFEF"
                  opacity="0.5"
                  minW="110px"
                  minH="24px"
                  p="1"
                  borderRadius="4"
                  my="2"
                  mr="auto"
                >
                  <HStack w="100%" justifyContent="space-between">
                    <Svg src={JobIcon} />
                    <Text>{order.driverName || '-'}</Text>
                    <div />
                  </HStack>
                </Box>
              </Tooltip>
            </Box>
          </AssignDriver>
        )}
      </HStack>
      <HStack>
        {!!order.paymentAmount && (
          <Tooltip label="Collect on delivery" hasArrow>
            <Box
              bg="#EFEFEF"
              py="1"
              px="3"
              borderRadius="4"
              display="flex"
              w="max-content"
              color="primary.900"
            >
              <IconWText
                text={order.paymentAmount ? `${order.paymentAmount}` : '0'}
                Icon={<Svg src={MoneyIcon} fill="currentColor" />}
                color="currentColor"
                pr="4px"
              />
              {order.payment?.status === 'APPROVED' && (
                <Svg src={VerifyIcon} width="14px" height="14px" fill="primary.900" />
              )}
            </Box>
          </Tooltip>
        )}
        {order?.shouldLeaveAtTheDoor && (
          <Tooltip label="Leave at the door" hasArrow>
            <Box
              bg="#EFEFEF"
              py="1"
              px="3"
              borderRadius="4"
              display="flex"
              w="max-content"
              color="primary.900"
            >
              <Svg src={LeaveAtTheDoorIcon} width="14px" height={'17px'} />
            </Box>
          </Tooltip>
        )}
        {!!order.b2bHandling && (
          <AttachmentsModal
            size="sm"
            title="B2B invoice"
            attachments={order.b2bInvoice ?? []}
            attachmentsURLs={order.b2bInvoice ?? []}
            button={
              <Tooltip label="B2B Invoice" hasArrow>
                <Box
                  bg="#EFEFEF"
                  py="1"
                  px="3"
                  borderRadius="4"
                  display="flex"
                  w="max-content"
                  color="primary.900"
                >
                  <Svg src={B2bIcon} width="20px" height={'20px'} />
                </Box>
              </Tooltip>
            }
          />
        )}

        {!!order.notes && <EditOrderRemarks orderId={order.id} notes={order.notes} />}

        {!!order.isRemote && (
          <Tooltip label={'Remote'} hasArrow>
            <Box
              bg="#EFEFEF"
              py="1"
              px="3"
              borderRadius="4"
              display="flex"
              w="max-content"
              color="primary.900"
            >
              <Svg src={RemoteIcon} width={'15px'} />
            </Box>
          </Tooltip>
        )}
        {!!order.isInternational && (
          <Tooltip label={'International'} hasArrow>
            <Box
              bg="#EFEFEF"
              py="1"
              px="3"
              borderRadius="4"
              display="flex"
              w="max-content"
              color="primary.900"
            >
              <Svg src={InternationalIcon} width={'15px'} />
            </Box>
          </Tooltip>
        )}
        {!!order.oversized && (
          <Tooltip label={oversizedComment} hasArrow>
            <Box
              bg="#EFEFEF"
              py="1"
              px="3"
              borderRadius="4"
              display="flex"
              w="max-content"
              color="primary.900"
            >
              <Svg src={OversizedIcon} width="14px" height={'17px'} />
            </Box>
          </Tooltip>
        )}
        {!!order.chilled && (
          <Tooltip label={'Chilled Order'} hasArrow>
            <Box
              bg="blue.500"
              py="1"
              px="3"
              borderRadius="4"
              display="flex"
              w="max-content"
              color="primary.900"
            >
              <Svg src={ChilledIcon} width="14px" height={'17px'} />
            </Box>
          </Tooltip>
        )}
        {!!order.attachments?.length && (
          <Box cursor="pointer">
            <AttachmentsModal
              attachments={order.attachments || []}
              attachmentsURLs={order.attachments || []}
              button={
                <Tooltip label="view Attachment" hasArrow>
                  <Box
                    bg="#EFEFEF"
                    px="3"
                    borderRadius="4"
                    w="max-content"
                    h="25px"
                    color="primary.900"
                  >
                    <Svg src={AttachmentIcon} width="12px" stroke="currentColor" />
                  </Box>
                </Tooltip>
              }
            />
          </Box>
        )}
        {order.retailer.procedures?.length &&
          order.retailer.procedures.some((sop) =>
            [ProcedureType.DISPATCH, ProcedureType.DRIVER].includes(sop.type)
          ) && (
            <RevealSOPs
              SOPTypes={[ProcedureType.DISPATCH, ProcedureType.DRIVER]}
              procedures={order.retailer.procedures}
            />
          )}
      </HStack>
    </>
  );
};
