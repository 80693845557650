import { useMutation } from '@apollo/client';
import { toast } from '@swftbox/style-guide';
import { CHANGE_PLAN_CUSTOMER_QUERY, GET_SUBSCRIPTION_QUERY } from '../Subscription.graphql';

interface QueryResponse {
  changeSubscriptionPlan: {
    message: string;
  };
}
interface QueryInput {
  changeSubscriptionPlanInput: { id: string; planId: string };
}

interface Props {
  retailerId: string;
  subscriptionId: string;
  planId: string;
}
export function useChangePlanRetailerQuery({ retailerId, subscriptionId, planId }: Props) {
  const [mutate, rest] = useMutation<QueryResponse, QueryInput>(CHANGE_PLAN_CUSTOMER_QUERY);
  const handleChangePlan = () => {
    return mutate({
      variables: {
        changeSubscriptionPlanInput: { id: subscriptionId, planId },
      },
      onCompleted: ({ changeSubscriptionPlan }) => toast.success(changeSubscriptionPlan.message),
      refetchQueries: [
        {
          query: GET_SUBSCRIPTION_QUERY,
          variables: {
            retailerSubscribePlanInput: {
              retailerId,
            },
          },
        },
      ],
    });
  };
  return {
    handleChangePlan,
    ...rest,
  };
}
