import {
  Badge,
  Box,
  Button,
  HStack,
  IconWText,
  Text,
  toast,
  Tooltip,
  VStack,
} from '@swftbox/style-guide';
import SVG from 'react-inlinesvg';
import PackageIcon from 'src/assets/icons/Development/package.svg';
import { ExchangeBadge } from '../Common';
import { useMemo } from 'react';
import {
  useOrderModalActions,
  type Order,
  OrderGroupType,
  OrderState,
} from 'src/components/Particles';
import { type OrdersStatusFilter } from '../../order.types';
import { statusNameMask } from '../Filters';
import { OrderCheckbox } from '../TableCells';
import SpotIcon from 'src/assets/icons/shapes/spot.svg';
import style from 'src/components/Pages/Orders/style.module.scss';
import clsx from 'clsx';
import WarningIcon from 'src/assets/icons/warning-exclamation.svg';
import { Config } from 'src/config';
import { StarOrder } from '../StarOrder';

interface ReferenceProps {
  order: Order;
}

export const Reference = ({ order }: ReferenceProps) => {
  const { name, bg, border, color } = useMemo(
    () => statusNameMask(order.status as OrdersStatusFilter),
    [order]
  );
  const dispatch = useOrderModalActions();
  const lastComment = order.comments?.[order.comments.length - 1] ?? undefined;
  const isMissed = !!lastComment && lastComment.type === 'MISSED' && order.status === 'attempted';

  const handleCopy = (e: any) => {
    void navigator.clipboard.writeText(e.target.innerHTML);
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    toast.success(`${e.target.innerHTML} Copied!`);
  };

  const groupOrder = order?.groupedOrders?.find((groupOrder) => groupOrder.id !== order.id);

  return (
    <>
      <HStack spacing="24px" cursor="default" w="100%" position="relative">
        <VStack textAlign="center" w="10%">
          {!Config.PRIMEXPRESS_BUILD && order.internationalDetails?.errors?.length && (
            <Button
              onClick={() => {
                dispatch({ type: 'OPEN_INTERNATIONAL_SHIPMENT_ERRORS', payload: order });
              }}
              variant="link"
              _hover={{ textDecor: 'none' }}
            >
              <Tooltip label="International order has errors">
                <Box>
                  <SVG src={WarningIcon} width="15px" height="15px" fill={'#F6CA45'} />
                </Box>
              </Tooltip>
            </Button>
          )}
          <OrderCheckbox order={order} />
        </VStack>
        <Box w="90%">
          {order.groupType === OrderGroupType.EXCHANGE && groupOrder ? (
            groupOrder.isReverse ? (
              <HStack mb="1">
                <ExchangeBadge state={order.state} isReverse={order.isReverse} />
                {[OrderState.cancelled, OrderState.rtoComplete, OrderState.delivered].includes(
                  order.state
                ) && <ExchangeBadge state={groupOrder.state} isReverse={groupOrder.isReverse} />}
              </HStack>
            ) : (
              <HStack mb="1">
                <ExchangeBadge state={groupOrder.state} isReverse={groupOrder.isReverse} />
                <ExchangeBadge state={order.state} isReverse={order.isReverse} />
              </HStack>
            )
          ) : (
            order.isReverse && (
              <Box mb="1">
                <ExchangeBadge state={order.state} isReverse={order.isReverse} />
              </Box>
            )
          )}

          <Box w="100%">
            <HStack justifyContent="space-between" w="100%">
              <Text
                color="primary.900"
                fontWeight="bold"
                fontSize="x-small"
                cursor="copy"
                onClick={handleCopy}
                w="100%"
                align="start"
              >
                {order.reference}
              </Text>
            </HStack>
            <Text color="gray.900" fontSize="x-small" fontWeight="semibold">
              {order.retailer.name}
            </Text>
          </Box>
          <HStack my="1" justifyContent="space-around" w="100%">
            <Button
              onClick={() => {
                dispatch({ type: 'OPEN_ORDER_DETAILS', payload: order });
              }}
              variant="link"
              _hover={{ textDecor: 'none' }}
              w="100%"
              justifyContent="flex-start"
            >
              <Badge
                py="1px"
                fontWeight="medium"
                borderRadius="8px"
                width="130px"
                textAlign="center"
                fontSize="x-small"
                border="1px solid"
                borderColor={border}
                bg={bg}
                color={color}
                textTransform="capitalize"
                whiteSpace="pre-line"
              >
                {isMissed ? 'Missed' : name}
              </Badge>
              {order.arrivingShortly && (
                <Tooltip label="Arriving Shortly">
                  <Box>
                    <SVG
                      src={SpotIcon}
                      className={clsx({
                        [style.spot]: true,
                      })}
                    />
                  </Box>
                </Tooltip>
              )}
            </Button>
            <StarOrder order={order} />
          </HStack>

          {!!order.failedAttemptCount && (
            <>
              <Badge
                py="1px"
                fontWeight="medium"
                borderRadius="4px"
                textAlign="center"
                fontSize="text-sm"
                border="1px solid rgba(255, 101, 101, 1)"
                bg="#fff"
                color="rgba(255, 101, 101, 1)"
                textTransform="capitalize"
                mb="1"
                mr="0.5"
              >
                {order.failedAttemptCount >= 1 && 'Attempted '}
                <Text as="span" fontSize="7px">
                  x
                </Text>
                {order.failedAttemptCount}
              </Badge>
              <Badge
                py="1px"
                fontWeight="medium"
                borderRadius="4px"
                textAlign="center"
                fontSize="text-sm"
                border="1px solid"
                display={order.failedAttemptCount < 3 ? 'none' : 'inline-block'}
                borderColor="#6764EC"
                bg="#F6DCFF"
                color="#6764EC"
                textTransform="capitalize"
                mb="1"
                px="2"
              >
                RTO ELIGIBLE
              </Badge>
            </>
          )}
          <HStack>
            <Text color="gray.900" fontSize="x-small" cursor="copy" onClick={handleCopy}>
              {order.swftboxTracking}
            </Text>
            {order.packageCount > 1 && (
              <>
                <Text color="gray.400">|</Text>
                <HStack gap="2">
                  <Tooltip label="Package Count" hasArrow>
                    <Box
                      bg={+order.packageCount > 1 ? 'rgba(255, 58, 110, 1)' : '#fff'}
                      color={+order.packageCount > 1 ? '#fff' : 'gray.900'}
                      p="0px 6px"
                      borderRadius="6px"
                    >
                      <IconWText
                        color="inherit"
                        text={`x${order.packageCount?.toString()}`}
                        fontSize="x-small"
                        Icon={<SVG src={PackageIcon} width="15px" stroke="currentColor" />}
                      />
                    </Box>
                  </Tooltip>
                </HStack>
              </>
            )}
          </HStack>
        </Box>
      </HStack>
    </>
  );
};
