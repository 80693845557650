import { Box, SBModal } from '@swftbox/style-guide';
import {
  useOrderModalActions,
  useOrderModalContext,
  type Order,
  OrderGroupType,
  AttachmentLens,
} from 'src/components/Particles';
import {
  DeliveryAttachments,
  ImageLens,
  OrderDetailsHeader,
  OrderDetailsItems,
  OrderHistory,
  OrderSuperHistory,
  OriginalPayload,
} from './Components';
import { CustomerDetails } from './Components/Tabs/CustomerDetails';
import { useEffect, useMemo, useState } from 'react';
import { NavigationTabs } from 'src/components/Organisms';
import ExchangeDetails from './Components/Tabs/ExchangeDetails';
import { NavigateNextAndPrevious } from './Components/NavigateNextAndPrevious/NavigateNextAndPrevious';
import CommentsTable from '../Comment/CommentsTable';

enum tabsIds {
  history = 'HISTORY',
  superHistory = 'SUPER_HISTORY',
  customerDetails = 'CUSTOMER_DETAILS',
  items = 'ITEMS',
  deliveryAttachments = 'DELIVERY_ATTACHMENTS',
  exchangeDetails = 'EXCHANGE_DETAILS',
  originalPayload = 'ORIGINAL_PAYLOAD',
  attachmentLens = 'ATTACHMENT_LENS',
  comments = 'COMMENTS',
}
interface props {
  attachment?: AttachmentLens | null;
  isOpen?: boolean;
  handleClose?: () => void;
  attachments?: AttachmentLens[];
  currentIndex?: number;
  setCurrentIndex?: (index: number) => void;
  handleUpdateStatus?: (status: 'passed' | 'rejected', id: string) => void;
  handleAddMore?: () => void;
  total?: number;
}
export const OrderDetailsModal = ({
  attachment,
  isOpen,
  handleClose,
  attachments,
  currentIndex,
  setCurrentIndex,
  handleUpdateStatus,
  handleAddMore,
  total,
}: props) => {
  const { orderDetailsIsOpen, data: orderInfo } = useOrderModalContext() as {
    orderDetailsIsOpen: boolean;
    data: Order;
  };
  const order = attachment?.order || orderInfo;
  const viewTabs = useMemo(() => {
    const tabs = [
      { name: 'History', id: tabsIds.history },
      { name: 'Detailed History', id: tabsIds.superHistory },
      { name: 'Customer Details', id: tabsIds.customerDetails },
      { name: 'Items', id: tabsIds.items },
    ];
    if (
      order.deliveryNoteUrls?.length ||
      order?.proofOfId?.length ||
      order?.customerProofSignatureUrl ||
      order?.attachments?.length
    ) {
      tabs.push({ name: 'Delivery Attachments', id: tabsIds.deliveryAttachments });
    }

    if (order.groupType === OrderGroupType.EXCHANGE) {
      tabs.push({ name: 'Exchange Details', id: tabsIds.exchangeDetails });
    }

    if (order.integration) {
      tabs.push({ name: 'Original Payload', id: tabsIds.originalPayload });
    }
    if (attachment) {
      tabs.unshift({ name: 'Comments', id: tabsIds.comments });
    }
    if (attachment) {
      tabs.unshift({ name: 'Image Lens', id: tabsIds.attachmentLens });
    }

    return tabs;
  }, [order]);

  const dispatch = useOrderModalActions();
  const [activeTab, setActiveTab] = useState<tabsIds | undefined>();
  const handleTabChange = (id: string) => {
    setActiveTab(id as tabsIds);
  };
  useEffect(() => {
    setActiveTab(isOpen ? tabsIds.attachmentLens : tabsIds.history);
  }, [isOpen]);
  const handleCloseModal = () => {
    if (handleClose) {
      handleClose();
    } else {
      dispatch({ type: 'CLOSE_ORDER_DETAILS' });
    }
    setActiveTab(isOpen ? tabsIds.attachmentLens : tabsIds.history);
  };
  const groupOrder = order?.groupedOrders?.find((groupOrder) => groupOrder.id !== order.id);
  return (
    <>
      <SBModal
        isOpen={!!order?.id && (orderDetailsIsOpen || !!isOpen)}
        onClose={handleCloseModal}
        header={
          <OrderDetailsHeader
            order={order}
            tabsButtons={
              <NavigationTabs handleChange={handleTabChange} tabs={viewTabs} active={activeTab} />
            }
          />
        }
        size="5xl"
        body={
          <Box h={attachment ? 'calc(100vh - 420px)' : '300px'}>
            {activeTab === tabsIds.history && <OrderHistory orderId={order?.id} />}
            {activeTab === tabsIds.superHistory && <OrderSuperHistory orderId={order?.id} />}
            {activeTab === tabsIds.customerDetails && <CustomerDetails order={order} />}
            {activeTab === tabsIds.items && (
              <OrderDetailsItems orderId={order?.id} groupOrder={groupOrder} />
            )}
            {activeTab === tabsIds.originalPayload && <OriginalPayload orderId={order?.id} />}

            {activeTab === tabsIds.deliveryAttachments && (
              <DeliveryAttachments
                deliveryNotes={order?.deliveryNoteUrls}
                proofOfId={order?.proofOfId}
                customerProofSignatureUrl={order?.customerProofSignatureUrl}
                attachments={order?.attachments}
              />
            )}
            {activeTab === tabsIds.exchangeDetails && <ExchangeDetails groupOrder={groupOrder} />}
            {activeTab === tabsIds.attachmentLens && attachment && (
              <ImageLens
                attachment={attachment}
                handleUpdateStatus={handleUpdateStatus}
                failedAttemptCount={order?.failedAttemptCount}
              />
            )}
            {activeTab === tabsIds.comments && attachment && (
              <CommentsTable comments={attachment?.order?.comments} />
            )}

            {attachments && isOpen && setCurrentIndex && (
              <NavigateNextAndPrevious
                attachments={attachments}
                isOpen={isOpen}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                handleAddMore={handleAddMore}
                total={total}
              />
            )}
          </Box>
        }
        bordered={false}
        footer={<div />}
      />
    </>
  );
};
