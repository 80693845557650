import { Box, HStack, Text } from '@swftbox/style-guide';
import { useCallback, useEffect } from 'react';
import { VerticalTabs } from 'src/components/Organisms';
import { InvoiceGenerationType, Retailer } from 'src/components/Particles';
import {
  AllSubtabsEnum,
  LegalAndBillingSubtabsEnum,
  MainTabsEnum,
  subtabMapping,
} from '../../Helper';
import { BillingInfoSettings } from './Components/BillingInfoSettings';
import { LegalInfoSettings } from './Components/LegalInfoSettings';
import { SubscriptionSetting } from './Components/SubscriptionSetting';

interface Props {
  retailer: Retailer;
  setAllowToMove: React.Dispatch<React.SetStateAction<{ page: string; isAllowed: boolean }>>;
  allowToMove: { page: string; isAllowed: boolean };
  activeTab: AllSubtabsEnum;
  setActiveTab: React.Dispatch<React.SetStateAction<AllSubtabsEnum>>;
}
export const LegalAndBillingDetails = ({
  retailer,
  activeTab,
  allowToMove,
  setActiveTab,
  setAllowToMove,
}: Props) => {
  const handleChangeTab = useCallback(
    (tabId: string) => {
      if (!allowToMove?.isAllowed) {
        setAllowToMove({ isAllowed: false, page: tabId });
      } else {
        setActiveTab(tabId as LegalAndBillingSubtabsEnum);
      }
    },
    [allowToMove]
  );
  useEffect(() => {
    setActiveTab(LegalAndBillingSubtabsEnum.LegalInformation);
  }, []);
  const legalBillingTabs = subtabMapping[MainTabsEnum.LegalAndBilling];
  return (
    <Box h="100%">
      <HStack align="start" h="calc(100% - 50px)" flexWrap="wrap">
        <Box position="fixed" top="125" left="5" zIndex={2} bg="#fff" h="100%" w="165px">
          <VerticalTabs tabs={legalBillingTabs} handleChange={handleChangeTab} active={activeTab} />
        </Box>

        <Box flexGrow="1" h="100%" ps="210px" pt="1">
          {activeTab === LegalAndBillingSubtabsEnum.LegalInformation && (
            <LegalInfoSettings
              retailer={retailer}
              legalInfo={retailer.legalInfo}
              email={retailer.email}
              id={retailer.id}
              setAllowToMove={setAllowToMove}
            />
          )}
          {activeTab === LegalAndBillingSubtabsEnum.BillingInformation && (
            <BillingInfoSettings
              id={retailer.id}
              billingInfo={retailer.billingInfo}
              setAllowToMove={setAllowToMove}
              defaultInvoiceType={
                retailer.retailerConfiguration?.defaultInvoiceType || InvoiceGenerationType.GENERAL
              }
            />
          )}
          {activeTab === LegalAndBillingSubtabsEnum.SubscriptionSettings && (
            <SubscriptionSetting
              retailerId={retailer.id}
              setAllowToMove={setAllowToMove}
              retailerEmail={retailer.email}
            />
          )}
        </Box>
      </HStack>
    </Box>
  );
};
