import { useMutation } from '@apollo/client';
import { toast } from '@swftbox/style-guide';
import { DELETE_PLAN_CUSTOMER_QUERY, GET_SUBSCRIPTION_QUERY } from '../Subscription.graphql';

interface QueryResponse {
  cancelSubscription: {
    message: string;
  };
}
interface QueryInput {
  cancelSubscriptionInput: { id: string; cancelImmediately?: boolean };
}

interface Props {
  retailerId: string;
  subscriptionId: string;
  cancelImmediately: boolean;
}
export function useCancelPlanRetailerQuery({
  retailerId,
  subscriptionId,
  cancelImmediately,
}: Props) {
  const [mutate, rest] = useMutation<QueryResponse, QueryInput>(DELETE_PLAN_CUSTOMER_QUERY);
  const handleCancelPlan = () => {
    return mutate({
      variables: {
        cancelSubscriptionInput: { id: subscriptionId, cancelImmediately },
      },
      onCompleted: ({ cancelSubscription }) => toast.success(cancelSubscription.message),
      refetchQueries: [
        {
          query: GET_SUBSCRIPTION_QUERY,
          variables: {
            retailerSubscribePlanInput: {
              retailerId,
            },
          },
        },
      ],
    });
  };
  return {
    handleCancelPlan,
    ...rest,
  };
}
