import React, { useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  GridItem,
  IconButton,
  SBInput,
  SBTrashIcon,
  toast,
  SBModal,
  HStack,
} from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { type Item, useUpdateItem, type UpdateItemInput } from 'src/components/Particles';
import { EmptyTable } from 'src/components/Organisms/EmptyTable';
import EmptyBox from 'src/assets/emptyBox.svg';
import { AddItem } from '../AddItem';

interface OrderItemsTableProps {
  items: Item[];
  onRemoveItem: (item: Item) => void;
  onClose: () => void;
  isOpen: boolean;
  orderId: string;
  loading: boolean;
}

interface FormData {
  items: Item[];
}

export const OrderItemsTable = (props: OrderItemsTableProps) => {
  const { items, onRemoveItem, onClose, isOpen, orderId, loading } = props;
  const { handleSubmit, register, setFocus } = useForm<FormData>({
    defaultValues: { items },
  });

  const formRef = useRef<HTMLFormElement>(null);

  const { callUpdateItem, loading: updating } = useUpdateItem();

  const onSubmit = (data: FormData) => {
    const itemsPayload: UpdateItemInput[] = [];
    data.items.forEach((item) => {
      if (item.serialNumbers[0] !== null) {
        itemsPayload.push({
          id: item.id,
          serialNumbers: item.serialNumbers,
          price: item.price ? Number(item.price) : undefined,
          hsCode: item.hsCode ? String(item.hsCode) : undefined,
        });
      }
    });

    void callUpdateItem({
      payload: itemsPayload,
      onCompleted: ({ updateItems }) => {
        toast.success(updateItems.message);
        onClose();
      },
    });
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    itemIndex: number,
    numberIndex: number
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const form = formRef.current;
      if (form) {
        const nextIndex = numberIndex + 1;
        const nextInput = form.elements[
          //  @ts-expect-error
          `items.${itemIndex}.serialNumbers.${nextIndex}`
        ] as HTMLInputElement;
        if (nextInput) {
          nextInput.focus();
        } else {
          const nextItem = form.elements[
            //  @ts-expect-error
            `items.${itemIndex + 1}.serialNumbers.0`
          ] as HTMLInputElement;
          if (nextItem) {
            nextItem.focus();
          }
        }
      }
    }
  };

  useEffect(() => {
    if (isOpen && !!items.length) {
      setTimeout(() => setFocus(`items.0.serialNumbers.0`), 100); // set focus on the input field with the name 'packageCount'
    }
  }, [isOpen, setFocus, items]);

  return (
    <SBModal
      isOpen={isOpen}
      onClose={onClose}
      type="general"
      header={<AddItem orderId={orderId} />}
      size="5xl"
      body={
        <Box bg="gray.100" borderRadius="8px" p="3">
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Grid
              gridTemplateColumns="repeat(12,1fr) 15px"
              color="gray.700"
              fontWeight="semibold"
              borderBottom="1px solid"
              borderColor="gray.500"
              gap="3"
              py="3"
              mb="5"
              position="sticky"
              top="0px"
              zIndex="100"
              bg="gray.100"
            >
              <GridItem colSpan={2}> SKU number</GridItem>
              <GridItem colSpan={2}>Item Name</GridItem>
              <GridItem colSpan={2}>Barcode</GridItem>
              <GridItem colSpan={1}>Qty</GridItem>
              <GridItem colSpan={2}>Serial Numbers</GridItem>
              <GridItem colSpan={2}>HS Code</GridItem>
              <GridItem colSpan={1}>Price</GridItem>
              <GridItem></GridItem>
            </Grid>

            {items.map((item: Item, index: number) => (
              <Grid
                gridTemplateColumns="repeat(12,1fr)  15px"
                key={item.id}
                gap="3"
                color="primary.900"
                fontWeight="medium"
                fontSize="x-small"
                _notLast={{ mb: '2.5', pb: '2.5', borderBottom: '3px solid white' }}
              >
                <GridItem colSpan={2} display="flex" alignItems="center" wordBreak="break-all">
                  {item.skuNumber}
                </GridItem>
                <GridItem colSpan={2} display="flex" alignItems="center" overflow="hidden">
                  {item.sku ?? '-'}
                </GridItem>
                <GridItem colSpan={2} display="flex" alignItems="center" overflow="hidden">
                  {item.barcode ?? '-'}
                </GridItem>
                <GridItem colSpan={1} display="flex" alignItems="center">
                  {item.quantity}
                </GridItem>
                <GridItem colSpan={2}>
                  {Array.from({ length: item.quantity }).map((_, numberIndex) => {
                    const numberValue = item.serialNumbers?.[numberIndex] || '';
                    return (
                      <Box m={'0.5em'} key={numberIndex}>
                        <SBInput
                          crossOrigin={undefined}
                          onKeyDown={(event) => {
                            handleKeyDown(event, index, numberIndex);
                          }}
                          placeholder="Serial Number"
                          {...register(`items.${index}.serialNumbers.${numberIndex}`)}
                          defaultValue={numberValue}
                        />
                      </Box>
                    );
                  })}
                </GridItem>
                <GridItem colSpan={2} display="flex" alignItems="center">
                  <SBInput
                    type="string"
                    crossOrigin={undefined}
                    placeholder="HS Code"
                    {...register(`items.${index}.hsCode`)}
                    defaultValue={item.hsCode}
                  />
                </GridItem>
                <GridItem colSpan={1} display="flex" alignItems="center">
                  <SBInput
                    type="number"
                    crossOrigin={undefined}
                    placeholder="Item Price"
                    {...register(`items.${index}.price`)}
                    defaultValue={item.price}
                  />
                </GridItem>
                <GridItem display="flex" alignItems="center">
                  <SBInput
                    crossOrigin={undefined}
                    type="hidden"
                    {...register(`items.${index}.id`)}
                    value={item.id}
                  />
                  <IconButton
                    size="small"
                    variant="link"
                    _hover={{
                      opacity: '0.5',
                    }}
                    aria-label="Delete"
                    color="gray.400"
                    onClick={() => {
                      onRemoveItem(item);
                    }}
                    icon={<SBTrashIcon width="15px" />}
                  />
                </GridItem>
              </Grid>
            ))}
          </form>
          {!items.length && (
            <Box w="100%" h="200px" justifyContent="center" alignItems="center" display="flex">
              {!loading ? <EmptyTable icon={EmptyBox} title="No Items Found" /> : <>Loading...</>}
            </Box>
          )}
        </Box>
      }
      footer={
        <HStack justifyContent="end" w="100%">
          <HStack>
            <HStack>
              <Button mx={1} variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button mx={1} disabled={updating} onClick={handleSubmit(onSubmit)}>
                Save Changes
              </Button>
            </HStack>
          </HStack>
        </HStack>
      }
    />
  );
};
