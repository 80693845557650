import StarIcon from 'src/assets/icons/star.svg';
import { useEffect, useState } from 'react';
import { Tooltip, Box } from '@swftbox/style-guide';
import {
  type OrderFlag,
  useFlagOrder,
  useUpdateOrderFlag,
  OrderFlagType,
  OrderFlagStatus,
  type Order,
} from 'src/components/Particles';
import SVG from 'react-inlinesvg';

interface StarOrderProps {
  order: Order;
}

export const StarOrder = ({ order }: StarOrderProps) => {
  const [starFlag, setStarFlag] = useState<OrderFlag | null>(null);

  const { flagOrder: createFlag, loading: createFlagLoading } = useFlagOrder();
  const { updateOrderFlag: updateFlag, loading: updateFlagLoading } = useUpdateOrderFlag();

  useEffect(() => {
    setStarFlag(
      order.orderFlags?.filter(
        (flag) =>
          flag.flagType === OrderFlagType.star &&
          [OrderFlagStatus.pending, OrderFlagStatus.actionTaken].includes(flag.status)
      )[0] ?? null
    );
  }, [order]);

  function onFlag() {
    if (createFlagLoading || updateFlagLoading) return;
    if (starFlag) {
      updateStarFlag(starFlag.id);
    } else {
      createStarFlag();
    }
  }

  function createStarFlag() {
    void createFlag({
      payload: {
        orderFlagInput: {
          orderId: order.id,
          flagType: OrderFlagType.star,
        },
      },
      onCompleted: ({ createOrderFlag }) => {
        if (createOrderFlag.message) {
          setStarFlag(createOrderFlag.data);
        }
      },
    });
  }

  function updateStarFlag(id: string) {
    void updateFlag({
      payload: {
        updateOrderFlagInput: {
          id,
          status: OrderFlagStatus.resolved,
        },
      },
      onCompleted: ({ updateOrderFlag }) => {
        if (updateOrderFlag.message) {
          setStarFlag(null);
        }
      },
    });
  }
  return (
    <Tooltip label={`Tap to ${starFlag ? 'un-star' : 'star'} order`} hasArrow>
      <Box onClick={onFlag} cursor="pointer">
        <SVG
          src={StarIcon}
          stroke={starFlag ? 'black' : '#efefef'}
          fill={starFlag ? '#ffe500' : '#efefef'}
          strokeWidth="25"
          width="15"
          style={{ marginLeft: '-2px' }}
        />
      </Box>
    </Tooltip>
  );
};
