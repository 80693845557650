import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  ResponsiveMap,
  SBEditIcon,
  SBInput,
  SBModal,
  Stack,
  Text,
  toast,
  type AutocompleteMapLocation,
  Button,
  HStack,
} from '@swftbox/style-guide';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  useOrderModalActions,
  useOrderModalContext,
  useUpdateLocation,
  type AddressInput,
} from 'src/components/Particles';
import { type EditCustomerAddressType } from 'src/components/Particles/Modules/OrderModule/types';
import { UpdateLocationSchema } from './order.schema';
import { error } from 'pdf-lib';

export function EditCustomerLocation() {
  const { customerAddressIsOpen, data: props } = useOrderModalContext() as {
    customerAddressIsOpen: boolean;
    data: EditCustomerAddressType;
  };

  const dispatch = useOrderModalActions();

  const currentLocation = useMemo(
    () => ({
      lat: props?.coordinates?.latitude ?? 25.456744,
      lng: props?.coordinates?.longitude ?? 55.676685,
    }),
    [props]
  );

  const {
    register,
    setValue,
    getValues,
    clearErrors,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<AddressInput>({
    mode: 'all',
    resolver: yupResolver(UpdateLocationSchema),
    defaultValues: {
      ...props,
      coordinates: props?.coordinates?.latitude ?
        props.coordinates :
        { latitude: 25.456744, longitude: 55.676685 },
    },
  });

  const { updateLocation } = useUpdateLocation();

  const handleCloseModal = (message?: string) => {
    if (message) toast.success(message);
    if (!message) reset();
    dispatch({ type: 'CLOSE_EDIT_CUSTOMER_ADDRESS' });
  };

  useEffect(() => {
    reset({
      ...props,
      coordinates: props?.coordinates?.latitude ?
        props.coordinates :
        { latitude: 25.456744, longitude: 55.676685 },
    });
  }, [customerAddressIsOpen]);

  function onConfirm(data: AddressInput) {
    if ('id' in props) {
      const payload: EditCustomerAddressType = {
        ...data,
        id: props?.id as string,
        verificationSource: undefined,
        // If default coordinates used, send undefined to run geocoder
        coordinates: data.coordinates?.latitude === 25.456744 && data.coordinates?.longitude === 55.676685 ? undefined : data.coordinates,
      };

      void updateLocation({
        payload,
        onCompleted: (message) => {
          handleCloseModal(message);
        },
      });
    }
  }

  function onLocationChange(location: AutocompleteMapLocation) {
    setValue('city', location.city || getValues('city'));
    setValue('country', location.country || getValues('country'));
    setValue('countryCode', location.countryCode || getValues('countryCode'));
    setValue('coordinates.latitude', location.coordinates.lat);
    setValue('coordinates.longitude', location.coordinates.lng);
    clearErrors();
  }

  console.log('propsss', props);
  console.log('errorsss', errors);

  useEffect(() => {
    if (errors.coordinates && Object.keys(errors).length === 1 && errors.coordinates.message === 'coordinates cannot be null') {
      clearErrors();
    }
  }, [errors, clearErrors]);

  return (
    <>
      <SBModal
        isOpen={customerAddressIsOpen}
        onClose={handleCloseModal}
        header={
          <Box pb="1vh">
            <Box
              mb="4"
              boxShadow="xs"
              border="1px solid #eaeCF0"
              w="48px"
              h="48px"
              borderRadius="10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#fbc70091"
              color="black"
            >
              <SBEditIcon fill="black" stroke="currentColor" viewBox="0 -3 20 28" />
            </Box>
            <Text>Edit Customer Location</Text>
          </Box>
        }
        size="3xl"
        h={'fit-content'}
        body={
          <Grid
            templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
            as="form"
            onSubmit={handleSubmit(onConfirm)}
            gap={4}
            alignItems={'center'}
          >
            <GridItem h="100%">
              <FormControl mb="3" h="100%">
                {customerAddressIsOpen && (
                  <>
                    <FormLabel>Place and drag your pin to the correct location</FormLabel>
                    <ResponsiveMap
                      containerHeight="400px"
                      onLocationChange={onLocationChange}
                      h="85%"
                      borderRadius="10px"
                      center={currentLocation}
                      // isBothCentered={true}
                      containerLabel={''}
                    />
                    <HStack>
                      <SBInput
                        crossOrigin={undefined}
                        label="City *"
                        type="text"
                        {...register('city')}
                        error={errors.city?.message}
                      />
                      <SBInput
                        crossOrigin={undefined}
                        required
                        label="Country *"
                        type="text"
                        {...register('country')}
                        error={errors.country?.message}
                      />
                    </HStack>
                    <Text mt="2">Coordinates:</Text>
                    <Button
                      as="a"
                      href={`https://www.google.com/maps?q=${getValues(
                        'coordinates.latitude'
                      )},${getValues('coordinates.longitude')}`}
                      target="_blank"
                      variant="link"
                      color="blue.500"
                      width="fit-content"
                    >
                      {`${getValues('coordinates.latitude')},${getValues('coordinates.longitude')}`}
                    </Button>
                  </>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <Stack gap={1}>
                <SBInput
                  crossOrigin={undefined}
                  label="Address Line 1 *"
                  type="text"
                  {...register('addressLine1')}
                  error={errors.addressLine1?.message}
                />
                <SBInput
                  crossOrigin={undefined}
                  label="Address Line 2"
                  type="text"
                  {...register('addressLine2')}
                  error={errors.addressLine2?.message}
                />
                <HStack>
                  <SBInput
                    crossOrigin={undefined}
                    label="Unit Number"
                    type="text"
                    {...register('building')}
                    error={errors.building?.message}
                  />
                  <SBInput
                    crossOrigin={undefined}
                    label="Zip/Postal Code"
                    type="text"
                    {...register('zipPostalCode')}
                    error={errors.zipPostalCode?.message}
                  />
                </HStack>
                <SBInput
                  crossOrigin={undefined}
                  label="Area"
                  type="text"
                  {...register('area')}
                  error={errors.area?.message}
                />
                <SBInput
                  crossOrigin={undefined}
                  label="State / Province"
                  type="text"
                  {...register('stateProvince')}
                  error={errors.stateProvince?.message}
                />
                <SBInput
                  crossOrigin={undefined}
                  label="Location Phone Number"
                  type="text"
                  {...register('phone')}
                  error={errors.phone?.message}
                />
              </Stack>
            </GridItem>
          </Grid>
        }
        handleConfirm={handleSubmit(onConfirm)}
        //  @ts-expect-error dummy
        scrollBehavior="outside"
        // isCentered={false}
      />
    </>
  );
}
