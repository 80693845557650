import { Table, TableContainer, Th, Thead, Tr } from '@swftbox/style-guide';
import { SmallPaginate } from 'src/components/Organisms/SmallPagination';
import {
  OrderState,
  useFilterOrdersQuery,
  useFulfillmentOrdersFilters,
  useFulfillmentOrdersFiltersActions,
  useSearch,
} from 'src/components/Particles';
import { headers } from '../Mocking';
import { SelectionOptions } from './SelectionOptions';
import { useEffect } from 'react';
import TableBody from './TableBody';
import { handleORClauseFilter } from '../../Orders/Components/helper';

const initialOrdersQueryVariables = {
  perPage: 50,
};
const OrdersTable = () => {
  const { searchText } = useSearch();
  const FilterAction = useFulfillmentOrdersFiltersActions();
  const {
    filters: { page, sortFilter, statusId, flagType },
    organizedFilters,
  } = useFulfillmentOrdersFilters();

  const { getOrders, orders, totalOrders, loading } = useFilterOrdersQuery(
    initialOrdersQueryVariables
  );
  useEffect(() => {
    getOrders({
      page,
      states: [OrderState.fulfillment],
      flagType: flagType?.length ? flagType : undefined,
      ...sortFilter,
      search: searchText ?? undefined,
      ...organizedFilters,
      ...(statusId && handleORClauseFilter(undefined, null, null, statusId)),
      ...(organizedFilters.dateFilter?.isSelected && {
        dateRange: {
          key: 'createdAt',
          from: organizedFilters.dateFilter.startDate,
          to: organizedFilters.dateFilter.endDate,
        },
      }),
    });
  }, [getOrders, page, sortFilter, searchText, organizedFilters, statusId, flagType]);

  return (
    <>
      <SelectionOptions />
      <TableContainer whiteSpace="normal">
        <Table variant="striped">
          <Thead position="relative">
            <Tr bg="#fbfbfc">
              {headers.map((header, index) => (
                <Th {...header.style} key={index}>
                  {header.name}
                </Th>
              ))}
              <Th right="0">
                <SmallPaginate
                  totalOrders={totalOrders}
                  page={page ?? 1}
                  perPage={50}
                  onPageChange={(page) => {
                    FilterAction({ type: 'ORDER_PAGE_CHANGE', payload: { page } });
                  }}
                  onSortChange={(sort) => {
                    FilterAction({ type: 'ORDER_SORT_CHANGE', payload: { sortFilter: sort } });
                  }}
                />
              </Th>
            </Tr>
          </Thead>
          <TableBody orders={orders} loading={loading} />
        </Table>
      </TableContainer>
    </>
  );
};

export default OrdersTable;
