import { Box, HStack, IconWText, Tooltip } from '@swftbox/style-guide';
import { type Order } from 'src/components/Particles';
import VerifyIcon from 'src/assets/icons/General/verify.svg';
import SVG from 'react-inlinesvg';
import MoneyIcon from 'src/assets/icons/orders/dollar.svg';
import { RescheduleModal } from '../Modal/Reschedule';
import { FlagOrder } from 'src/components/Pages/Orders/Components';

interface DispatchPlanProps {
  order: Order;
}

export const DispatchPlan = ({ order }: DispatchPlanProps) => {
  return (
    <>
      <HStack alignItems={'flex-start'}>
        <FlagOrder order={order} isFulfillment={true} />
        <RescheduleModal order={order} />
      </HStack>
      <HStack h="40px">
        {!!order.paymentAmount && (
          <Tooltip label="Collect on delivery" hasArrow>
            <Box bg="#EFEFEF" py="1" px="3" display="flex" borderRadius="4" my="2" w="max-content">
              <IconWText
                text={`${order.paymentAmount ?? 0}`}
                Icon={<SVG src={MoneyIcon} />}
                pr="4px"
              />
              {order.payment?.status === 'APPROVED' && (
                <SVG src={VerifyIcon} width="14px" height="14px" fill="black" />
              )}
            </Box>
          </Tooltip>
        )}
      </HStack>
    </>
  );
};
