import { yupResolver } from '@hookform/resolvers/yup';
import { Button, SBInput, Stack, VStack, toast, Box } from '@swftbox/style-guide';
import { useForm } from 'react-hook-form';
import { useUpdateRetailerMutation } from 'src/components/Particles';
import { EmailSettingsSchema } from '../../../../Components/Modals/Validation';
import { isEqual } from '../../../Helper';
import { useEffect } from 'react';
import { SectionRow, SubmitAndCancel } from '../../Shared';

interface PartnerSettingsProps {
  id: string;
  emailManifest?: string;
  incomingPickupEmail?: string;
  emailDailyRecap?: string;
  flaggedOrderEmail?: string;
  setAllowToMove: React.Dispatch<React.SetStateAction<{ page: string; isAllowed: boolean }>>;
}

interface FormValues {
  id: string;
  emailManifest?: string;
  incomingPickupEmail?: string;
  emailDailyRecap?: string;
  flaggedOrderEmail?: string;
}

export function EmailSettings(props: Readonly<PartnerSettingsProps>) {
  const {
    id,
    emailManifest,
    incomingPickupEmail,
    emailDailyRecap,
    flaggedOrderEmail,
    setAllowToMove,
  } = props;

  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      id,
      emailManifest,
      incomingPickupEmail,
      flaggedOrderEmail,
      emailDailyRecap,
    },
    resolver: yupResolver(EmailSettingsSchema),
  });

  const { updateRetailer, loading } = useUpdateRetailerMutation();

  const onSubmit = handleSubmit(async (data) => {
    await updateRetailer(data);
    toast.success('Retailer settings updated successfully');
  });
  const watchedValues = watch();

  const hasChanges =
    (isDirty &&
      (!isEqual(watchedValues.emailManifest, emailManifest) ||
        !isEqual(watchedValues.incomingPickupEmail, incomingPickupEmail) ||
        !isEqual(watchedValues.emailDailyRecap, emailDailyRecap))) ||
    !isEqual(watchedValues.flaggedOrderEmail, flaggedOrderEmail);
  useEffect(() => {
    if (hasChanges) {
      setAllowToMove({ page: '', isAllowed: false });
    } else {
      setAllowToMove({ page: '', isAllowed: true });
    }
  }, [hasChanges]);
  return (
    <Box as="form" onSubmit={onSubmit} h="100%">
      <VStack alignItems="stretch" justifyContent="space-between" h="100%" gap="4">
        <Stack spacing={0}>
          <SectionRow
            title="Email Manifests "
            description="Email manifests are sent to specify the orders that were picked up during a partner pickup, returned during a partner RTOs, or returned for customer Returns.
            Separate multiple emails with commas"
            children={
              <SBInput
                crossOrigin={undefined}
                type="text"
                {...register('emailManifest')}
                placeholder="ex: swftbox@swftbox.com, swftbox2@swftbox.com"
                error={errors.emailManifest?.message}
              />
            }
          />
          <SectionRow
            title="Daily Recap"
            description="Daily Recap emails are sent at the end of the day to summarize all the updates with deliveries and returns.
            Separate multiple emails with commas."
            children={
              <SBInput
                crossOrigin={undefined}
                type="text"
                {...register('emailDailyRecap')}
                placeholder="ex: swftbox@swftbox.com, swftbox2@swftbox.com"
                error={errors.emailDailyRecap?.message}
              />
            }
          />
          <SectionRow
            title="Incoming Pickup Emails"
            description="These emails notify the partner when a driver is on the way for a pickup.
            Separate multiple emails with commas."
            children={
              <SBInput
                crossOrigin={undefined}
                type="text"
                {...register('incomingPickupEmail')}
                placeholder="ex: swftbox@swftbox.com, swftbox2@swftbox.com"
                error={errors.incomingPickupEmail?.message}
              />
            }
          />
          <SectionRow
            title="Flagged Order Notifications"
            description="These emails notify merchants of any flags that are applied to an order that require action for the order to proceed. Separate multiple emails with commas."
            children={
              <SBInput
                crossOrigin={undefined}
                type="text"
                {...register('flaggedOrderEmail')}
                placeholder="ex: swftbox@swftbox.com, swftbox2@swftbox.com"
                error={errors.flaggedOrderEmail?.message}
              />
            }
          />
        </Stack>

        <SubmitAndCancel isHidden={hasChanges} reset={reset} loading={loading} />
      </VStack>
    </Box>
  );
}
