import { Badge, Button, HStack, Image, Text, VStack } from '@swftbox/style-guide';
import { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import XIcon from 'src/assets/icons/General/x.svg';
import checkIcon from 'src/assets/icons/check.svg';
import { statusBadgeVariant, tagsBadgeVariant } from 'src/components/Pages/OperationLens/LensCard';
import { type AttachmentLens } from 'src/components/Particles';

interface ImageLensProps {
  attachment: AttachmentLens;
  handleUpdateStatus?: (status: 'passed' | 'rejected', id: string) => void;
  failedAttemptCount: number;
}

export const ImageLens = ({
  attachment,
  handleUpdateStatus,
  failedAttemptCount,
}: ImageLensProps) => {
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'a' && attachment.status !== 'passed') {
        handleUpdateStatus?.('passed', attachment.id);
      } else if (event.key === 'f' && attachment.status !== 'rejected') {
        handleUpdateStatus?.('rejected', attachment.id);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [attachment, handleUpdateStatus]);

  return (
    <HStack alignItems="start" h="100%">
      <Image src={attachment.attachment} alt={attachment.comment} h="100%" objectFit="cover" />
      <VStack position="sticky" top="0" h="100%" justifyContent="space-between" alignItems="start">
        <VStack justifyContent="start" alignItems="start" display="text-xl" color="gray.600">
          <Text>
            Status:{' '}
            <Text
              textTransform="capitalize"
              as="span"
              fontSize="display-xs"
              fontWeight="semibold"
              color={statusBadgeVariant(attachment?.status).color}
            >
              {attachment?.status}
            </Text>
          </Text>
          {!!attachment?.photoLabelTags?.length && (
            <HStack>
              <Text display="text-xl" color="gray.600">
                Tags:{' '}
              </Text>
              {attachment?.photoLabelTags?.map((name) => (
                <Badge variant={tagsBadgeVariant(name).variant} size="md" key={name}>
                  {tagsBadgeVariant(name).name}
                </Badge>
              ))}
            </HStack>
          )}
          {failedAttemptCount && (
            <HStack>
              <Badge
                py="1px"
                fontWeight="medium"
                borderRadius="4px"
                textAlign="center"
                fontSize="text-sm"
                border="1px solid rgba(255, 101, 101, 1)"
                bg="#fff"
                color="rgba(255, 101, 101, 1)"
                textTransform="capitalize"
                ml="12"
                my="2"
              >
                {failedAttemptCount >= 1 && 'Attempted '}
                <Text as="span" fontSize="7px">
                  x
                </Text>
                {failedAttemptCount}
              </Badge>
            </HStack>
          )}
        </VStack>
        <HStack>
          {attachment.status !== 'rejected' && (
            <Button
              borderColor="red.500"
              bg="#fff"
              p="1"
              w="150px"
              color="red.500"
              onClick={() => {
                handleUpdateStatus?.('rejected', attachment.id);
              }}
            >
              <SVG src={XIcon} stroke="red" />
              <Text>Reject</Text>
            </Button>
          )}
          {attachment.status !== 'passed' && (
            <Button
              borderColor="green.500"
              bg="#fff"
              p="1"
              w="150px"
              color="green.500"
              onClick={() => {
                handleUpdateStatus?.('passed', attachment.id);
              }}
            >
              {' '}
              <SVG src={checkIcon} stroke="green" />
              <Text>Pass</Text>
            </Button>
          )}
        </HStack>
      </VStack>
    </HStack>
  );
};
