import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { type clientOptionType } from '../SharedTypes';
import { REFRESH_PAYMENT_STATUS } from './orders.graphql';

interface MutationResponse {
  refreshPaymentStatus: {
    success: boolean;
    message: string;
  };
}

interface MutationProps {
  onCompleted?: (data: MutationResponse, clientOptions?: clientOptionType) => void;
}

export function useRefreshPaymentStatusMutation({ onCompleted }: MutationProps) {
  const [mutate, { loading, error }] = useMutation<MutationResponse>(REFRESH_PAYMENT_STATUS, {
    onCompleted,
  });

  const refreshPaymentStatus = useCallback(
    (orderId: string) => {
      void mutate({ variables: { orderId } });
    },
    [mutate]
  );

  return {
    refreshPaymentStatus,
    loading,
    error,
  };
}
