import { gql } from '@apollo/client';

export const SUBSCRIPTION_FRAGMENT = gql`
  fragment SubscriptionFragment on SubscriptionDto {
    id
    endDate
    startDate
    status
    isPaused
    isCanceled
    stripeSubscriptionId
    plan {
      name
      price
      currency
      id
    }
  }
`;

export const GET_SUBSCRIPTION_QUERY = gql`
  query getSubscriptionPlan($retailerSubscribePlanInput: RetailerSubscribePlanInput!) {
    retailerSubscription(RetailerSubscribePlanInput: $retailerSubscribePlanInput) {
      ...SubscriptionFragment
    }
  }
  ${SUBSCRIPTION_FRAGMENT}
`;

export const UPDATE_ASSIGN_PLAN_CUSTOMER_QUERY = gql`
  mutation AssignPlanToRetailer($subscribePlanInput: SubscribePlanInput!) {
    assignPlanToRetailer(SubscribePlanInput: $subscribePlanInput) {
      id
      endDate
      startDate
      status
      plan {
        name
        price
        currency
        id
      }
    }
  }
`;
export const DELETE_PLAN_CUSTOMER_QUERY = gql`
  mutation CancelSubscription($cancelSubscriptionInput: CancelSubscriptionInput!) {
    cancelSubscription(CancelSubscriptionInput: $cancelSubscriptionInput) {
      message
    }
  }
`;
export const PAUSE_PLAN_CUSTOMER_QUERY = gql`
  mutation PauseSubscription($updateSubscriptionInput: UpdateSubscriptionInput!) {
    pauseSubscription(UpdateSubscriptionInput: $updateSubscriptionInput) {
      message
    }
  }
`;
export const RESUME_PLAN_CUSTOMER_QUERY = gql`
  mutation ResumeSubscription($updateSubscriptionInput: UpdateSubscriptionInput!) {
    resumeSubscription(UpdateSubscriptionInput: $updateSubscriptionInput) {
      message
    }
  }
`;

export const CHANGE_PLAN_CUSTOMER_QUERY = gql`
  mutation ChangeSubscriptionPlan($changeSubscriptionPlanInput: ChangeSubscriptionPlanInput!) {
    changeSubscriptionPlan(ChangeSubscriptionPlanInput: $changeSubscriptionPlanInput) {
      message
    }
  }
`;
